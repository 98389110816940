import React, { useState, Suspense, lazy } from "react";
import CustomModal from "components/CustomModal";
import { CustomTable } from "components/CustomTable";
import RenderTableActionsButton from "components/RenderTableActionsButton";
import {
  STRING_CONSTANTS,
  ROUTE_CONSTANTS,
  STATIC_VALUES,
  REDUX_KEYS_ACTIONS,
} from "constants/common";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { useSelector } from "react-redux";

const MainLayout = lazy(() => import("layouts/MainLayout"));
const RenderProgramMasterFilters = lazy(() =>
  import("./RenderProgramMasterFilters")
);

const ProgramMaster = () => {
  const { programMasterReduxData } = useSelector(
    (state) => state[REDUX_KEYS_ACTIONS.PROGRAM_MASTER.FEATURE_KEY]
  );
  const navigate = useNavigate();
  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const defaultModalProps = { isOpen: false, data: null };
  const [modal, setModal] = useState(defaultModalProps);

  const columns = [
    {
      field: "id",
      header: STATIC_VALUES.COLUMNS.COMMON.ID,
    },
    {
      field: "name",
      header: STATIC_VALUES.COLUMNS.PROGRAM_MASTER.NAME,
      enableSort: true,
    },
    {
      field: "schoolCategory",
      header: STATIC_VALUES.COLUMNS.COMMON.SCHOOl_CATEGORY,
      enableSort: true,
    },
    {
      field: "schoolType",
      header: STATIC_VALUES.COLUMNS.COMMON.SCHOOL_TYPE,
      enableSort: true,
    },
    {
      field: "educationType",
      header: STATIC_VALUES.COLUMNS.COMMON.EDUCATION_TYPE,
      enableSort: true,
    },
    {
      field: "createdDate",
      header: STATIC_VALUES.COLUMNS.COMMON.CREATED_DATE,
      enableSort: true,
    },
    {
      field: "updatedDate",
      header: STATIC_VALUES.COLUMNS.COMMON.UPDATED_DATE,
      enableSort: true,
    },
    {
      field: "action",
      header: STATIC_VALUES.COLUMNS.COMMON.ACTIONS,
      shouldRenderElement: true,
      renderElement: () => {
        return (
          <RenderTableActionsButton
            setModal={setModal}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleDeleteClick={() => setModal({ data, isOpen: true })}
            showViewIcon
            showDeleteIcon
            showEditIcon
            handleViewRoute={ROUTE_CONSTANTS.VIEW_PROGRAM_MASTER}
            handleEditRoute={ROUTE_CONSTANTS.EDIT_PROGRAM_MASTER}
          />
        );
      },
    },
  ];

  const data = [
    {
      id: "1",
      name: "John Brown",
      schoolCategory: "School",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "2",
      name: "Jim Green",
      schoolCategory: "School",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "3",
      name: "Joe Black",
      schoolCategory: "School",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "4",
      name: "Jim Green",
      schoolCategory: "School",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "5",
      name: "Joe Black",
      schoolCategory: "School",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "6",
      name: "Jim Green",
      schoolCategory: "School",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "7",
      name: "Joe Black",
      schoolCategory: "School",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "8",
      name: "Jim Green",
      schoolCategory: "School",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
  ];

  const onChangeData = () => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleAddRoute = (params, error) => {
    navigate(ROUTE_CONSTANTS.ADD_PROGRAM_MASTER);
  };

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  return (
    <Suspense>
      <MainLayout>
        {modal?.isOpen ? (
          <CustomModal
            modal={modal?.isOpen}
            setModal={() => setModal(defaultModalProps)}
            modalTitle={"Delete Modal"}
            modalInnerContent={STRING_CONSTANTS.COMMON.RECORD_DELETE_STRING}
            footerBtnText={"Yes"}
          />
        ) : null}
        <CustomTable
          showAddButton={true}
          handleAddRoute={handleAddRoute}
          isFilter={isFilter}
          addBtnText={"Add"}
          tableHeader={"Program Master"}
          handleIsFilter={handleIsFilter}
          filterOptions={
            <Suspense>
              <RenderProgramMasterFilters
                showDateFilter={true}
                showMultiSelectFilter={true}
                multiSelectOptions={multiSelectOptions}
              />
            </Suspense>
          }
          data={data}
          columns={columns}
          count={10}
          width={"100"}
          noDataFoundMessage={
            STRING_CONSTANTS.PROGRAM_MASTER.NO_DATA_FOR_PROGRAM_MASTER_FOUND
          }
          class={"table_class"}
          onChangeData={onChangeData}
          btnMarginTop={false}
        />
      </MainLayout>
    </Suspense>
  );
};

export default ProgramMaster;
