export const ASC = "ASC";
export const DESC = "DESC";

export const STRING_CONSTANTS = {
  COMMON: {
    EDULAC_TITLE: "Edulac - Admin",
    RECORD_DELETE_STRING: "Are you sure you want to delete this record ?",
    NO_DATA_FOUND: "No Data found",
    SIGN_OUT_TITLE_CONFIRM_MESSAGE: "Are you sure you want to sign out ?",
    NO_DOCUMENTS_UPLOADED: "No Documents Uploaded",
    ENGLISH_LABEL: "(English)",
    FRENCH_LABEL: "(French)",
    DEFAULT_IMAGE_DESCRIPTION: "Only jpg or png file is allowed",
    DISABLE_RECORD_TITLE: "Are you sure you want to disable this record?",
    INVALID_FILE_FORMAT: "Invalid File Format",
    NO_NOTIFICATION_FOUND: "No notifications found.",
    NOTIFICATIONS_LBL_NAME: "Notifications",
    BUTTON: {
      SEARCH_TITLE: "Search",
      RESET_TITLE: "Reset",
      EXPORT_TITLE: "Export",
      SET_AS_POPULAR_TITLE: "Set As Popular",
      SET_AS_DUARATION_TITLE: "Set As Duration",
      SAVE_TITLE: "Save",
      EDIT_TITLE: "Edit",
      CANCEL_TITLE: "Cancel",
      SUBMIT_TITLE: "Submit",
      BACK_TITLE: "Back",
      YES_TITLE: "Yes",
      ADD_TITLE: "Add",
    },
    MODE: {
      ADD: "add",
      EDIT: "edit",
      VIEW: "view",
    },
    LANGUAGES_LIST: [
      { id: 1, languageCode: "en", name: "English" },
      { id: 2, languageCode: "fr", name: "French" },
    ],
  },
  SCHOOL_TYPE_MASTER: {
    NO_DATA_FOR_SCHOOL_TYPE_MASTER_FOUND:
      "No Data found for School type master",
  },
  PROGRAM_MASTER: {
    ADD_PROGRAM_MASTER: "Add Program Master",
    EDIT_PROGRAM_MASTER: "Edit Program Master",
    VIEW_PROGRAM_MASTER: "View Program Master",
    NO_DATA_FOR_PROGRAM_MASTER_FOUND: "No Data found for Program master",
  },
  PLAFORM_USER_TYPE_MASTER: {
    ADD_PLATFORM_USER_TYPE_MASTER: "Add Platform User Type Master",
    EDIT_PLATFORM_USER_TYPE_MASTER: "Edit Platform User Type Master",
    VIEW_PLATFORM_USER_TYPE_MASTER: "View Platform User Type Master",
    NO_DATA_FOR_PLAFORM_USER_TYPE_FOUND:
      "No Data found for Plaform user type master",
  },
  SCHOOL_FACILITY_MASTER: {
    ADD_SCHOOL_FACILITIES: "Add School Facilities",
    EDIT_SCHOOL_FACILITIES: "Edit School Facilities",
    VIEW_SCHOOL_FACILITIES: "View School Facilities",
    NO_DATA_FOR_SCHOOL_FACILITIES_FOUND:
      "No Data found for School facilities master",
  },
  SUBJECT_MASTER: {
    ADD_SUBJECT_MASTER: "Add Subject Master",
    EDIT_SUBJECT_MASTER: "Edit Subject Master",
    VIEW_SUBJECT_MASTER: "View Subject Master",
    NO_DATA_FOR_SUBJECT_MASTER_FOUND: "No Data found for the Subject master",
  },
  BANNER_MASTER: {
    ADD_BANNER_FORM_HEADING: "Add Banner Master",
    EDIT_BANNER_FORM_HEADING: "Edit Banner Master",
    LIST_BANNER_POSITION: ["Left", "Right", "Bottom"],
    LIST_DEFAULT_BANNER_TYPE: ["Yes", "No"],
    NO_DATA_FOR_BANNER_MANAGEMENT_FOUND:
      "No Data found for the Banner management",
  },
  GRADE_MASTER: {
    ADD_GRADE_MASTER: "Add Grade Master",
    EDIT_GRADE_MASTER: "Edit Grade Master",
    VIEW_GRADE_MASTER: "View Grade Master",
    NO_DATA_FOR_GRADE_MASTER_FOUND: "No Data found for the Grade master",
  },
  REQUEST_MANAGEMENT_MASTER: {
    NO_DATA_FOR_REQUEST_MANAGEMENT_MASTER_FOUND:
      "No Data found for the Request Management",
  },
  MASTER_MANAGEMENT: {
    NATION_MASTER: {
      HEADER_TITLE_LISTING: "Nation Listing",
      NO_DATA_FOR_NATION_MASTER_FOUND: "No Data found for the Nation master",
    },
    REGION_MASTER: {
      NO_DATA_FOR_REGION_MASTER_FOUND: "No Data found for the Region master",
    },
    PROVINCE_MASTER: {
      NO_DATA_FOR_PROVINCE_MASTER_FOUND:
        "No Data found for the Province master",
    },
    COMMUNE_MASTER: {
      NO_DATA_FOR_COMMUNE_MASTER_FOUND: "No Data found for the Commune master",
    },
    VILLAGE_SECTOR_MASTER: {
      NO_DATA_FOR_VILLAGE_SECTOR_MASTER_FOUND:
        "No Data found for the Village-Sector master",
    },
  },
  SCHOOL_MASTER: {
    HEADER_TITLE_SCHOOL_LISTING: "School Lisitng",
    HEADING_ADD_SCHOOL_MASTER: "Add School Master",
    HEADING_EDIT_SCHOOL_MASTER: "Edit School Master",
    HEADING_VIEW_SCHOOL_MASTER: "View School Master",
    NO_DATA_FOR_SCHOOL_MASTER_FOUND: "No schools found matching your criteria",
    FIELDSET_PERSONAL_INFORMATION: "Personal Information",
    FIELDSET_FACILITY_INFORMATION: "Facility Information",
  },
  POPULAR_SCHOOLS: {
    HEADER_TITLE_POPULAR_SCHOOLS: "Popular Schools",
  },
  MINISTER_USER_MANAGEMENT: {
    HEADER_TITLE_MINISTER_USER_MANAGEMENT: "Minister User Management",
    HEADING_VIEW_MINISTER_USER_MANAGEMENT: "View Minister User Management",
    NO_DATA_FOR_MINISTER_USER_FOUND:
      "No ministry users found matching your criteria.",
  },
  NEWS_MANAGEMENT: {
    HEADER_TITLE_NEWS_MANAGEMENT: "News Management",
    HEADER_TITLE_ADD_NEWS: "Add News",
    HEADER_TITLE_EDIT_NEWS: "Edit News",
    HEADER_TITLE_VIEW_NEWS: "View News",
    NO_DATA_FOR_NEWS_MANAGEMENT_FOUND: "No news found matching your criteria",
    FIELD_TITLE_ERROR_MESSAGE: "Please enter a valid Title",
    FIELD_DESCRIPTION_ERROR_MESSAGE: "Please enter a valid description.",
    FIELD_REDIRECTION_LINK_ERROR_MESSAGE: "Please enter a valid URL",
  },
  ARTICLE_MANAGEMENT: {
    HEADER_TITLE_ARTICLE_MANAGEMENT: "Article  Management",
    HEADING_VIEW_ARTICLE_USER_MANAGEMENT: "View Article Management",
    HEADER_TITLE_ADD_ARTICLE: "Add Article",
    HEADER_TITLE_EDIT_ARTICLE: "Edit Article",
    HEADER_TITLE_VIEW_ARTICLE: "View Article",
    NO_DATA_FOR_ARTICLE_MANAGEMENT_FOUND:
      "No article found matching your criteria",
  },
  AUTH: {
    LOGIN: {
      LOGIN_SIGNUP: "Sign In Your Account",
    },
    REGISTRATION: {},
    FORGOT_PASSWORD: {
      TITLE_FORGOT_PASSWORD: "Forgot Password",
    },
  },
};

export const ROUTE_CONSTANTS = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  FORGOT_PASSWORD: "/forgot-password",
  CREATE_NEW_PASSWORD: "/create-new-password",
  OTP_VERIFICATION: "/otp-verification",
  SCHOOL_TYPE_MASTER: "/school-type-master",
  PROGRAM_MASTER: "/program-master",
  ADD_PROGRAM_MASTER: "/add-program-master-route",
  EDIT_PROGRAM_MASTER: "/edit-program-master-route",
  VIEW_PROGRAM_MASTER: "/view-program-master-route",
  PLATFORM_USER_TYPE_MASTER: "/platform-user-type-master",
  EDIT_PLATFORM_USER_TYPE_MASTER: "/edit-platform-user-type-master",
  VIEW_PLATFORM_USER_TYPE_MASTER: "/view-platform-user-type-master",
  ADD_PLATFORM_USER_TYPE_MASTER: "/add-platform-user-type-master",
  SCHOOL_FACILITIES_MASTER: "/school-facilities-master",
  SUBJECT_MASTER: "/subject-master",
  MATER_MANAGEMENT: "/master-management",
  ADD_MASTER_MANAGEMENT: "/add-master-management",
  REQUEST_MANAGEMENT: "/request-management",
  ADD_SUBJECT_MASTER: "/add-subject-master",
  EDIT_SUBJECT_MASTER: "/edit-subject-master",
  VIEW_SUBJECT_MASTER: "/view-subject-master",
  ADD_SCHOOL_FACILITIES: "/add-school-facilities",
  EDIT_SCHOOL_FACILITIES: "/edit-school-facilities",
  VIEW_SCHOOL_FACILITIES: "/view-school-facilities",
  BANNER_MANAGEMENT: "/banner-management",
  ADD_BANNER: "/add-banner",
  EDIT_BANNER: "/edit-banner",
  SCHOOL_MASTER: "/schools-master",
  ADD_SCHOOL_MASTER: "/add-schools-master",
  EDIT_SCHOOL_MASTER: "/edit-schools-master",
  VIEW_SCHOOL_MASTER: "/view-schools-master",
  GRADE_MASTER: "/grade-master",
  EDIT_GRADE_MASTER: "/edit-grade-master",
  ADD_GRADE_MASTER: "/add-grade-master",
  VIEW_GRADE_MASTER: "/view-grade-master",
  MINISTER_USER_MANAGEMENT: "/minister-user-management",
  VIEW_MINISTER_USER_MANAGEMENT: "/view-minister-user-management",
  POPULAR_SCHOOLS: "/popular-schools",
  NEWS_MANAGEMENT: "/news-management",
  ADD_NEWS: "/add-news",
  EDIT_NEWS: "/edit-news",
  VIEW_NEWS: "/view-news",
  ARTICLE_MANAGEMENT: "/article-management",
  VIEW_ARTICLE: "/view-article",
  ADD_ARTICLE: "/add-article",
  EDIT_ARTICLE: "/edit-article",
};

export const STATIC_VALUES = {
  COLUMNS: {
    COMMON: {
      ID: "Id",
      STATUS: "Status",
      CREATED_DATE: "Created Date",
      UPDATED_DATE: "Updated Date",
      CREATED_BY: "Created By",
      CREATED_AT: "Created At",
      UPDATED_BY: "Updated By",
      UPDATED_AT: "Updated At",
      SCHOOL_TYPE: "School Type",
      EDUCATION_TYPE: "Education Type",
      SCHOOLS: "Schools",
      CATEGORY: "Category (Cycle)",
      PROGRAM: "Program",
      SCHOOl_STATUS: "School Status",
      SCHOOl_CATEGORY: "School Category",
      SCHOOL_NAME: "School Name",
      NAME_FRENCH: "Name(French)",
      NAME_ENGLISH: "Name(English)",
      ACTIONS: "Actions",
      SELECT: "Select",
      GENDER: "Gender",
      REGION: "Region",
      COMMUNE: "Commune",
      PROVINCE: "Province",
      REGISTERED_AT: "Registered At",
      MINISTER_ID: "Minister Id",
      EMAIL_ID: "Email Id",
      TITLE: "Title",
      THUMBNAILIMAGE: "ThumbNailImage",
    },
    DASHBOARD: {
      KEY: "Key",
      BOARD_NAME: "Board Name",
      STUDENT_NAME: "Student Name",
    },
    PROGRAM_MASTER: {
      NAME: "Name",
    },
    PLAFORM_USER_TYPE_MASTER: {
      PLAFORM_TYPE_NAME: "PlatForm Name",
      USER_TYPE: "User Type",
    },
    SCHOOL_FACILITIES_MASTER: {
      DESCRIPTION: "Description",
    },
    SUBJECT_MASTER: {
      SUBJECT_NAME: "Subject Name",
      GRADE: "Grade",
    },
    GRADE_MASTER: {
      GRADE_NAME: "Grade Name",
    },
    REQUEST_MANAGEMENT: {
      MINISTRY_TYPE: "Ministry Type",
      PHONE_NUMBER: "Phone Number",
      ID_PROOF_IMAGE: "Id Proof Image",
      PASSPORT_IMAGE: "Passport Image",
      DOCUMENT_IMAGE: "Document Image",
      ACCEPT_REJECT: "Accept/Reject",
    },
    BANNER_MANAGEMENT: {
      BANNER_TITLE: "Banner Title",
      BANNER_IMAGE_ENGLISH: "Banner Image (English)",
      BANNER_IAMGE_FRENCH: "Banner Image (French)",
      BANNER_THUMBNAIL: "Banner Thumbnail",
      BANNER_SEQUENCE: "Banner Sequence",
      BANNER_PLACE: "Banner Place",
      DEFAULT_BANNER_FLAG: "Default Banner Flag",
    },
    SCHOOL_MANAGEMENT_MASTER: {
      SCHOOL_CYCLE: "School Cycle",
      SCHOOL_EDUCATION_TYPE: "School Education Type",
      PARTNERSHIP_STATUS: "Partnership Status",
      ADMINISTRATIVE_STATUS: "Administrative Status",
      CONTACT_INFO: "Contact Info",
    },
    MINISTER_USER_MANAGEMENT: {
      MINISTER_NAME: "Minister Name",
      MINISTER_CYCLE: "Minister Cycle",
      CONATACT_INFORMATION: "Contact Information",
    },
    NEWS_MANAGEMENT: {
      NEWS_STATUS: "News Status",
      NEWS_REDIRECTION_LINK: "News RedirectionLink",
    },
  },
};

export const REGEX_STRINGS = {
  COMMON: {
    JPEG: /.(jpeg)$/,
  },
  NEWS_MANAGEMENT: {
    TITLE_NEWS: /^[^\s][\s\S]{1,10}$/,
    TITLE_DESCRIPTION: /^([A-Za-z0-9]+[A-Za-z0-9 ]*)$/,
    TITLE_REDIRECTION_LINK:
      /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
  },
};

export const REDUX_KEYS_ACTIONS = {
  ARTICLE_MANAGEMENT: {
    FEATURE_KEY: "ARTICLE_MGT_FEATURE_KEY",
    FETCH_ARTICLES: "FETCH_ARTICLES",
  },
  SCHOOL_TYPE_MASTER: {
    FEATURE_KEY: "SCHOOL_TYPE_MASTER_FEATURE_KEY",
    FETCH_SCHOOL_TYPE_MASTER_LIST: "FETCH_SCHOOL_TYPE_MASTER_LIST",
  },
  PROGRAM_MASTER: {
    FEATURE_KEY: "PROGRAM_MASTER_FEATURE_KEY",
    FETCH_PROGRAM_LIST: "FETCH_PROGRAM_LIST",
  },
  SUBJECT_MASTER: {
    FEATURE_KEY: "SUBJECT_MASTER_FEATURE_KEY",
    FETCH_SUBJECT_LIST: "FETCH_SUBJECT_LIST",
  },
  DASHBOARD: {
    FEATURE_KEY: "DASHBOARD_FEATURE_KEY",
    FETCH_DASHBOARD_LIST: "FETCH_DASHBOARD_LIST",
  },
  SCHOOL_FACILITIES_MASTER: {
    FEATURE_KEY: "SCHOOL_FACILITIES_FEATURE_KEY",
    FETCH_SCHOOL_FACILITIES_MASTER_LIST: "FETCH_SCHOOL_FACILITIES_MASTER_LIST",
  },
  GRADE_MASTER: {
    FEATURE_KEY: "GRADE_MASTER_FEATURE_KEY",
    FETCH_GRADE_MASTER_LIST: "FETCH_GRADE_MASTER_LIST",
  },
  BANNER_MANAGEMENT: {
    FEATURE_KEY: "BANNER_FEATURE_KEY",
    FETCH_BANNER_LIST: "FETCH_BANNER_LIST",
  },
  NEWS_MANAGEMENT: {
    FEATURE_KEY: "NEWS_FEATURE_KEY",
    FETCH_NEWS_LIST: "FETCH_NEWS_LIST",
  },
  MINISTER_USER_MANAGEMENT: {
    FEATURE_KEY: "MINISTER_USER_FEATURE_KEY",
    FETCH_MINISTER_USER_LIST: "FETCH_MINISTER_USER_LIST",
  },
  MASTER_MANAGEMENT: {
    FEATURE_KEY: "MASTER_MGT_FEATURE_KEY",
    FETCH_MASTER_LIST: "FETCH_MASTER_LIST",
  },
  PLAFORM_USER_TYPE_MASTER: {
    FEATURE_KEY: "PLAFORM_USER_FEATURE_KEY",
    FETCH_PLATFORM_USER_LIST: "FETCH_PLATFOTM_USER_LIST",
  },
  SCHOOL_MANAGEMENT: {
    FEATURE_KEY: "SCHOOL_MGT_FEATURE_KEY",
    FETCH_SCHOOL_MGT_LIST: "FETCH_SCHOOL_MGT_LIST",
  },
};
