import styled from "styled-components";

const StyledFlex = styled.div`
  display: flex;
  gap: 10px;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-top: ${({ marginTop }) =>
    marginTop !== undefined ? `${marginTop}px` : "0"};
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 520px) {
    grid-template-columns: 1fr;
  }
`;

const StyledSvg = styled.svg`
  cursor: pointer;
  width: ${({ width }) => width || "20.833"}px;
  height: ${({ height }) => height || "19.386"}px;
`;

const StyledProfileIcon = styled.svg`
  cursor: pointer;
  width: ${({ width }) => width || "20.833"}px;
  height: ${({ height }) => height || "19.386"}px;
  position: ${(props) => props?.position || "relative"};
  top: ${(props) => props?.top || "auto"};
`;

const StyledIconEyeClose = styled.svg`
  cursor: pointer;
  width: ${({ width }) => width || "20"}px;
  height: ${({ height }) => height || "20"}px;
`;

const StyledIconEyeOpen = styled.svg`
  cursor: pointer;
  width: ${({ width }) => width || "20"}px;
  height: ${({ height }) => height || "20"}px;
`;

const StyledIconBannerManagement = styled.svg`
  cursor: pointer;
  width: ${({ width }) => width || "20"}px;
  height: ${({ height }) => height || "20"}px;
`;

const StyledIconBell = styled.svg`
  cursor: pointer;
  width: ${({ width }) => width || "20"}px;
  height: ${({ height }) => height || "20"}px;
`;

export {
  StyledFlex,
  GridContainer,
  StyledSvg,
  StyledProfileIcon,
  StyledIconEyeClose,
  StyledIconEyeOpen,
  StyledIconBannerManagement,
  StyledIconBell,
};
