import React, { useEffect, useRef } from "react";
import { STRING_CONSTANTS } from "constants/common";

const Textarea = (props) => {
  const {
    label,
    placeholder,
    value,
    onChangeHandler,
    mode = STRING_CONSTANTS.COMMON.MODE.ADD,
    name,
    textareaClass = "",
    rows = "",
    cols = "",
    required = false,
    titleClass = "",
    isReadOnly = false,
    hasError = false,
    errorMessage = "",
    disableAutoFocus = false,
  } = props;
  const textareaRef = useRef(null);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef?.current?.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [value]);

  return (
    <div className={"custom-wrapper"}>
      <label
        className={`field-group-label ${
          required ? "required" : ""
        } ${titleClass}`}
      >
        <span>
          {label}
          {!isReadOnly ? (
            required ? (
              <span className="required-asterisk">*</span>
            ) : null
          ) : null}
        </span>
      </label>
      <div>
        {isReadOnly ? (
          <label className={`preview-text`}>{value ? value : "-"}</label>
        ) : (
          <textarea
            name={name}
            ref={textareaRef}
            className={`custom-input-field ${textareaClass}`}
            placeholder={placeholder}
            value={value}
            onChange={onChangeHandler}
            readOnly={mode === STRING_CONSTANTS.COMMON.MODE.VIEW}
            rows={rows}
            cols={cols}
            autoFocus={!disableAutoFocus}
          />
        )}
      </div>
      {hasError ? <p className="error-msg">{errorMessage}</p> : null}
    </div>
  );
};

export default Textarea;
