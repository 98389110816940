import React from "react";
import MainLayout from "layouts/MainLayout";
import FormLayout from "layouts/FormLayout";
import {
  STRING_CONSTANTS,
  ROUTE_CONSTANTS,
  REGEX_STRINGS,
} from "constants/common";
import { getLanguages } from "constants/utilities";
import { getArticleSchema } from "./ArticleUtils";
import { useForm } from "hooks/useForm";
import { GridContainer, StyledFlex } from "constants/commonStyles";
import CustomInput from "components/CustomInput";
import CustomFileInput from "components/CustomFileInput";
import { FieldTypes } from "constants/fieldTypes";
import CustomDropdown from "components/CustomDropdown";
import "./index.css";

const AddEditArticleManagement = (props) => {
  const { formHeading, mode } = props;
  const languages = getLanguages(STRING_CONSTANTS.COMMON.LANGUAGES_LIST);
  const initialState = getArticleSchema(languages);

  const onHandleArticleManagement = (params, error) => {
    if (mode) {
    } else {
    }
  };

  const {
    values,
    errors,
    dirty,
    handleOnSubmit,
    handleOnChange,
    handleOnSelection,
  } = useForm(initialState, onHandleArticleManagement);

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const callUploadApi = async (fileData) => {
    const fileSelected = fileData;
    const sizeMB = parseFloat(fileSelected.size / (1024 * 1024)).toFixed(2);
    if (!fileSelected.name.match(REGEX_STRINGS.COMMON.JPEG)) {
      return;
    }
    if (sizeMB > 5) {
      return;
    }
  };

  const commonInputProps = {
    type: FieldTypes.TYPE_TEXT,
    onChangeHandler: handleOnChange,
    disableAutoFocus: true,
    required: true,
    mode: mode,
  };

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={STRING_CONSTANTS.COMMON.BUTTON.CANCEL_TITLE}
        cancelBtnRoute={ROUTE_CONSTANTS.ARTICLE_MANAGEMENT}
        saveBtnText={STRING_CONSTANTS.COMMON.BUTTON.SAVE_TITLE}
        mode={mode}
        viewButtonText={STRING_CONSTANTS.COMMON.BUTTON.EDIT_TITLE}
        viewFormEditRoute={ROUTE_CONSTANTS.EDIT_ARTICLE}
      >
        {languages?.length > 0
          ? languages?.map((language) => (
              <div key={language?.languageCode}>
                <StyledFlex>
                  <CustomInput
                    name={`title-${language?.languageCode}`}
                    placeholder={`Title (${language?.name})`}
                    label={`Title (${language?.name})`}
                    value={values[language?.languageCode]?.title?.value}
                    hasError={
                      errors[language?.languageCode]?.title &&
                      dirty[language?.languageCode]?.title
                    }
                    errorMessage={errors[language?.languageCode]?.title}
                    mode={mode}
                    minLength={2}
                    maxLength={99}
                    {...commonInputProps}
                  />
                  <CustomInput
                    name={`description-${language?.languageCode}`}
                    placeholder={`Description (${language?.name})`}
                    label={`Description (${language?.name})`}
                    value={values?.[language?.languageCode]?.description?.value}
                    hasError={
                      errors[language?.languageCode]?.description &&
                      dirty[language?.languageCode]?.description
                    }
                    errorMessage={errors[language?.languageCode]?.description}
                    maxLength={500}
                    {...commonInputProps}
                  />
                </StyledFlex>
                <GridContainer marginTop={"10"}>
                  <CustomFileInput
                    fieldName={`thumbNailImage-${language?.languageCode}`}
                    title={`ThumbNail Image (${language?.name})`}
                    description={`Upload An Valid Image (JPEG, max size 5 MB) (${language?.name})`}
                    hasPreview={
                      values?.[language?.languageCode]?.thumbNailImage?.value
                    }
                    preview={
                      values?.[language?.languageCode]?.thumbNailImage?.value
                    }
                    className="article-thumbail-image"
                    accepts={"image/jpeg"}
                    isDragAndDropRequired={true}
                    disableAutoFocus={true}
                    required={true}
                    onChangeHandler={callUploadApi}
                  />
                </GridContainer>
              </div>
            ))
          : null}
        <StyledFlex>
          <CustomDropdown
            fieldName={"status"}
            label={"Select Status"}
            onChangeHandler={handleOnSelection}
            value={values?.status}
            placeholder="Select Status"
            options={options}
            allowClear={true}
            mode={mode}
            required={true}
            classes={"article-dropdown-width"}
          />
        </StyledFlex>
      </FormLayout>
    </MainLayout>
  );
};

export default AddEditArticleManagement;
