import Dropdown from "components/CustomDropdown";
import Input from "components/CustomInput";
import Textarea from "components/CustomTextarea";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";
import { StyledFlex } from "constants/commonStyles";
import { FieldTypes } from "constants/fieldTypes";
import { useForm } from "hooks/useForm";
import FormLayout from "layouts/FormLayout";
import MainLayout from "layouts/MainLayout";
import React from "react";
import { useLocation } from "react-router-dom";

const ProgramMasterForm = ({ formHeading }) => {
  const location = useLocation();
  const mode = location?.state?.mode;

  const initialState = {
    programNameInEnglish: {
      value: "Dummy Program",
      error: "aa",
      required: true,
    },
    descriptionInEnglish: {
      value:
        "ong established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.",
      error: "",
    },
    programNameInFrench: {
      value: "Dummy Program",
      error: "aa",
      required: true,
    },
    descriptionInFrench: {
      value:
        "ong established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy.",
      error: "",
    },
    schoolType: {
      value: "Primary School",
      error: "",
    },
    grade: {
      value: "A+",
      error: "",
    },
    schoolCategory: {
      value: "Secondary",
      error: "",
    },
    schoolStatus: {
      value: "Active",
      error: "",
    },
    schoolEducationType: {
      value: "Formal Education",
      error: "",
    },
  };

  const handleGradeMasterSubmission = (params, error) => {
    if (mode) {
    } else {
    }
  };

  const { errors, handleOnSubmit, handleOnChange, handleOnSelection, values } =
    useForm(initialState, handleGradeMasterSubmission);

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const commonTextAreaProps = {
    type: FieldTypes.TYPE_TEXT,
    placeholder: "Add Description.",
    onChangeHandler: handleOnChange,
    mode: mode,
    textareaClass: "program-master-textarea-content",
    required: true,
    rows: "3",
    cols: "4",
  };

  const commonInputProps = {
    onChangeHandler: handleOnChange,
    mode: mode,
    required: true,
    type: FieldTypes.TYPE_TEXT,
  };

  const commonDropDownProps = {
    placeholder: "Select",
    options: options,
    onChangeHandler: handleOnSelection,
    allowClear: true,
    mode: mode,
    required: true,
  };

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={"Cancel"}
        cancelBtnRoute={ROUTE_CONSTANTS.PROGRAM_MASTER}
        saveBtnText={"Save"}
        mode={location?.state?.mode}
        viewButtonText={"Edit"}
        viewFormEditRoute={ROUTE_CONSTANTS.EDIT_PROGRAM_MASTER}
      >
        <StyledFlex>
          <Input
            name="programNameInEnglish"
            placeholder="Program Name"
            label={"Grade Name" + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values.programNameInEnglish}
            {...commonInputProps}
          />
          <Input
            name="programNameInFrench"
            placeholder="Program Name"
            label={"Grade Name" + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            mode={mode}
            value={values.programNameInFrench}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Textarea
            name="descriptionInEnglish"
            label={"Add Description." + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values.descriptionInEnglish}
            {...commonTextAreaProps}
          />
          <Textarea
            name="descriptionInFrench"
            label={"Add Description." + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values.descriptionInFrench}
            {...commonTextAreaProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            fieldName={"schoolType"}
            label={"Select School Type"}
            onChangeHandler={handleOnSelection}
            value={values.schoolType}
            placeholder="Select School Type"
            {...commonDropDownProps}
          />
          <Dropdown
            fieldName={"grade"}
            label={"Select Grade"}
            value={values.grade}
            placeholder="Select Grade"
            {...commonDropDownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            fieldName={"schoolCategory"}
            label={"Select School Category"}
            placeholder="Select School Category"
            value={values.schoolCategory}
            {...commonDropDownProps}
          />
          <Dropdown
            fieldName={"schoolStatus"}
            label={"Select School Status"}
            placeholder="Select School Status"
            value={values.schoolStatus}
            {...commonDropDownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            fieldName={"schoolEducationType"}
            label={"Select School Education Type"}
            value={values.schoolEducationType}
            placeholder="Select School Education Type"
            {...commonDropDownProps}
          />
        </StyledFlex>
      </FormLayout>
    </MainLayout>
  );
};

export default ProgramMasterForm;
