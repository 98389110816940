import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";

import DashboardComponent from "./components/Dashboard";
import Login from "./components/Login";
import SchoolTypeMasterComp from "./components/SchoolTypeMaster";
import ForgotPasswordComponent from "./components/ForgotPassword";
import CreateNewPassword from "./components/CreateNewPassword";
import OtpVerification from "./components/OtpVerification";
import ProgramMaster from "./components/ProgramMaster";
import PlatformUserTypeMaster from "./components/PlatformUserTypeMaster";
import PlatformUserTypeMasterForm from "./components/PlatformUserTypeMaster/PlatformUserTypeMasterForm";
import SchoolFacilitiesMaster from "components/SchoolFacilitiesMaster";
import SubjectMaster from "components/SubjectMaster";
import GradeMaster from "components/GradeMaster";
import GradeMasterForm from "./components/GradeMaster/GradeMasterForm";
import MasterManagement from "components/MasterManagement";
import ProgramMasterForm from "components/ProgramMaster/ProgramMasterForm";
import RequestManagement from "components/RequestManagement";
import SubjectMasterForm from "components/SubjectMaster/SubjectMasterForm";
import SchoolFacilitiesForm from "components/SchoolFacilitiesMaster/SchoolFacilitiesForm";
import BannerManagement from "components/BannerManagement";
import AddEditBanner from "components/BannerManagement/AddEditBanner";
import SchoolMaster from "components/SchoolMaster";
import AddEditSchoolMaster from "components/SchoolMaster/AddEditSchoolMaster";
import MinisterUserManagement from "components/MinisterUserManagement";
import AddEditMinisterUserManagement from "components/MinisterUserManagement/AddEditMinisterUserManagement";
import NewsManagement from "components/NewsManagement";
import AddEditNewsManagement from "components/NewsManagement/AddEditNewsManagement";
import ArticleManagement from "components/ArticleManagement";
import AddEditArticleManagement from "components/ArticleManagement/AddEditArticleManagement";
import "./App.css";

const router = createBrowserRouter([
  {
    path: ROUTE_CONSTANTS.HOME,
    element: <Login />,
  },
  {
    path: ROUTE_CONSTANTS.DASHBOARD,
    element: <DashboardComponent />,
  },
  {
    path: ROUTE_CONSTANTS.FORGOT_PASSWORD,
    element: <ForgotPasswordComponent />,
  },
  {
    path: "/school-type-master",
    element: <SchoolTypeMasterComp />,
  },
  {
    path: ROUTE_CONSTANTS.CREATE_NEW_PASSWORD,
    element: <CreateNewPassword />,
  },
  {
    path: ROUTE_CONSTANTS.OTP_VERIFICATION,
    element: <OtpVerification />,
  },
  {
    path: ROUTE_CONSTANTS.PROGRAM_MASTER,
    element: <ProgramMaster />,
  },
  {
    path: ROUTE_CONSTANTS.PLATFORM_USER_TYPE_MASTER,
    element: <PlatformUserTypeMaster />,
  },
  {
    path: ROUTE_CONSTANTS.VIEW_PLATFORM_USER_TYPE_MASTER,
    element: (
      <PlatformUserTypeMasterForm
        formHeading={
          STRING_CONSTANTS.PLAFORM_USER_TYPE_MASTER
            .VIEW_PLATFORM_USER_TYPE_MASTER
        }
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.ADD_PLATFORM_USER_TYPE_MASTER,
    element: (
      <PlatformUserTypeMasterForm
        mode={STRING_CONSTANTS.COMMON.MODE.ADD}
        formHeading={
          STRING_CONSTANTS.PLAFORM_USER_TYPE_MASTER
            .ADD_PLATFORM_USER_TYPE_MASTER
        }
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.EDIT_PLATFORM_USER_TYPE_MASTER,
    element: (
      <PlatformUserTypeMasterForm
        formHeading={
          STRING_CONSTANTS.PLAFORM_USER_TYPE_MASTER
            .EDIT_PLATFORM_USER_TYPE_MASTER
        }
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.SCHOOL_FACILITIES_MASTER,
    element: <SchoolFacilitiesMaster />,
  },
  {
    path: ROUTE_CONSTANTS.SUBJECT_MASTER,
    element: <SubjectMaster />,
  },
  {
    path: ROUTE_CONSTANTS.GRADE_MASTER,
    element: <GradeMaster />,
  },
  {
    path: ROUTE_CONSTANTS.EDIT_GRADE_MASTER,
    element: (
      <GradeMasterForm
        formHeading={STRING_CONSTANTS.GRADE_MASTER.EDIT_GRADE_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.ADD_GRADE_MASTER,
    element: (
      <GradeMasterForm
        mode={STRING_CONSTANTS.COMMON.MODE.ADD}
        formHeading={STRING_CONSTANTS.GRADE_MASTER.ADD_GRADE_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.VIEW_GRADE_MASTER,
    element: (
      <GradeMasterForm
        formHeading={STRING_CONSTANTS.GRADE_MASTER.VIEW_GRADE_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.MATER_MANAGEMENT,
    element: <MasterManagement />,
  },
  {
    path: ROUTE_CONSTANTS.ADD_PROGRAM_MASTER,
    element: (
      <ProgramMasterForm
        formHeading={STRING_CONSTANTS.PROGRAM_MASTER.ADD_PROGRAM_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.EDIT_PROGRAM_MASTER,
    element: (
      <ProgramMasterForm
        formHeading={STRING_CONSTANTS.PROGRAM_MASTER.EDIT_PROGRAM_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.VIEW_PROGRAM_MASTER,
    element: (
      <ProgramMasterForm
        formHeading={STRING_CONSTANTS.PROGRAM_MASTER.VIEW_PROGRAM_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.REQUEST_MANAGEMENT,
    element: <RequestManagement />,
  },
  {
    path: ROUTE_CONSTANTS.ADD_SUBJECT_MASTER,
    element: (
      <SubjectMasterForm
        mode={STRING_CONSTANTS.COMMON.MODE.ADD}
        formHeading={STRING_CONSTANTS.SUBJECT_MASTER.ADD_SUBJECT_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.EDIT_SUBJECT_MASTER,
    element: (
      <SubjectMasterForm
        formHeading={STRING_CONSTANTS.SUBJECT_MASTER.EDIT_SUBJECT_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.VIEW_SUBJECT_MASTER,
    element: (
      <SubjectMasterForm
        formHeading={STRING_CONSTANTS.SUBJECT_MASTER.VIEW_SUBJECT_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.ADD_SCHOOL_FACILITIES,
    element: (
      <SchoolFacilitiesForm
        mode={STRING_CONSTANTS.COMMON.MODE.ADD}
        formHeading={
          STRING_CONSTANTS.SCHOOL_FACILITY_MASTER.ADD_SCHOOL_FACILITIES
        }
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.EDIT_SCHOOL_FACILITIES,
    element: (
      <SchoolFacilitiesForm
        formHeading={
          STRING_CONSTANTS.SCHOOL_FACILITY_MASTER.EDIT_SCHOOL_FACILITIES
        }
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.VIEW_SCHOOL_FACILITIES,
    element: (
      <SchoolFacilitiesForm
        formHeading={
          STRING_CONSTANTS.SCHOOL_FACILITY_MASTER.VIEW_SCHOOL_FACILITIES
        }
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.BANNER_MANAGEMENT,
    element: <BannerManagement />,
  },
  {
    path: ROUTE_CONSTANTS.EDIT_BANNER,
    element: (
      <AddEditBanner
        mode={STRING_CONSTANTS.COMMON.MODE.EDIT}
        formHeading={STRING_CONSTANTS.BANNER_MASTER.EDIT_BANNER_FORM_HEADING}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.ADD_BANNER,
    element: (
      <AddEditBanner
        mode={STRING_CONSTANTS.COMMON.MODE.ADD}
        formHeading={STRING_CONSTANTS.BANNER_MASTER.ADD_BANNER_FORM_HEADING}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.SCHOOL_MASTER,
    element: <SchoolMaster />,
  },
  {
    path: ROUTE_CONSTANTS.ADD_SCHOOL_MASTER,
    element: (
      <AddEditSchoolMaster
        isAdd
        formHeading={STRING_CONSTANTS.SCHOOL_MASTER.HEADING_ADD_SCHOOL_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.VIEW_SCHOOL_MASTER,
    element: (
      <AddEditSchoolMaster
        formHeading={STRING_CONSTANTS.SCHOOL_MASTER.HEADING_VIEW_SCHOOL_MASTER}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.POPULAR_SCHOOLS,
    element: <SchoolMaster />,
  },
  {
    path: ROUTE_CONSTANTS.MINISTER_USER_MANAGEMENT,
    element: <MinisterUserManagement />,
  },
  {
    path: ROUTE_CONSTANTS.VIEW_MINISTER_USER_MANAGEMENT,
    element: (
      <AddEditMinisterUserManagement
        formHeading={
          STRING_CONSTANTS.MINISTER_USER_MANAGEMENT
            .HEADING_VIEW_MINISTER_USER_MANAGEMENT
        }
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.NEWS_MANAGEMENT,
    element: <NewsManagement />,
  },
  {
    path: ROUTE_CONSTANTS.ADD_NEWS,
    element: (
      <AddEditNewsManagement
        mode={STRING_CONSTANTS.COMMON.MODE.ADD}
        formHeading={STRING_CONSTANTS.NEWS_MANAGEMENT.HEADER_TITLE_ADD_NEWS}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.EDIT_NEWS,
    element: (
      <AddEditNewsManagement
        mode={STRING_CONSTANTS.COMMON.MODE.EDIT}
        formHeading={STRING_CONSTANTS.NEWS_MANAGEMENT.HEADER_TITLE_EDIT_NEWS}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.VIEW_NEWS,
    element: (
      <AddEditNewsManagement
        mode={STRING_CONSTANTS.COMMON.MODE.VIEW}
        formHeading={STRING_CONSTANTS.NEWS_MANAGEMENT.HEADER_TITLE_VIEW_NEWS}
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.ARTICLE_MANAGEMENT,
    element: <ArticleManagement />,
  },
  {
    path: ROUTE_CONSTANTS.ADD_ARTICLE,
    element: (
      <AddEditArticleManagement
        mode={STRING_CONSTANTS.COMMON.MODE.ADD}
        formHeading={
          STRING_CONSTANTS.ARTICLE_MANAGEMENT.HEADER_TITLE_ADD_ARTICLE
        }
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.EDIT_ARTICLE,
    element: (
      <AddEditArticleManagement
        mode={STRING_CONSTANTS.COMMON.MODE.EDIT}
        formHeading={
          STRING_CONSTANTS.ARTICLE_MANAGEMENT.HEADER_TITLE_EDIT_ARTICLE
        }
      />
    ),
  },
  {
    path: ROUTE_CONSTANTS.VIEW_ARTICLE,
    element: (
      <AddEditArticleManagement
        mode={STRING_CONSTANTS.COMMON.MODE.VIEW}
        formHeading={
          STRING_CONSTANTS.ARTICLE_MANAGEMENT.HEADER_TITLE_VIEW_ARTICLE
        }
      />
    ),
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
