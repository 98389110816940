import React from "react";
import Button from "components/CustomButton";
import { useNavigate } from "react-router-dom";
import { STRING_CONSTANTS } from "constants/common";
import "./index.css";

const FormLayout = ({
  children,
  formHeading,
  handleOnSubmit,
  cancelBtnText,
  cancelBtnRoute,
  saveBtnText,
  viewButtonText,
  viewFormEditRoute,
}) => {
  const navigate = useNavigate();

  return (
    <div className={"form-layout-main-wrapper"}>
      <div className={"form-layout-header-text"}>
        <div className={"form-layout-inner-wrapper"}>
          <p className={"form-layout-header"}>{formHeading}</p>
        </div>
      </div>
      {children}

      {STRING_CONSTANTS.COMMON.MODE.VIEW ? (
        <div className={"form-button-layout"}>
          <Button
            btnText={cancelBtnText}
            onClickHandler={() => navigate(cancelBtnRoute)}
            btnMarginTop
            width={100}
          />
          <Button
            btnText={saveBtnText}
            onClickHandler={handleOnSubmit}
            btnMarginTop
            width={100}
          />
        </div>
      ) : (
        <div className={"button-wrapper-for-view-form-edit-button"}>
          <Button
            btnText={viewButtonText}
            onClickHandler={() => navigate(viewFormEditRoute)}
            btnMarginTop
            width={"auto"}
          />
        </div>
      )}
    </div>
  );
};

export default FormLayout;
