import {
  IconBannerManagement,
  IconDashboard,
  IconFacilitiesMaster,
  IconGradeMaster,
  IconMasterManagement,
  IconProgramMaster,
  IconRequestManagement,
  IconSchoolTypeMaster,
  IconSubjectMaster,
  IconUserTypeMaster,
} from "assets/images/SVGs";

import { ROUTE_CONSTANTS } from "./common";

export const MENULIST = [
  {
    id: 1,
    icon: <IconDashboard width={18} height={18} />,
    text: "Dashboard",
    path: ROUTE_CONSTANTS.DASHBOARD,
  },
  {
    id: 2,
    icon: <IconSchoolTypeMaster width={18} height={18} />,
    text: "Schools Type Master",
    path: ROUTE_CONSTANTS.SCHOOL_TYPE_MASTER,
  },
  {
    id: 3,
    icon: <IconProgramMaster width={18} height={18} />,
    text: "Program Master",
    path: ROUTE_CONSTANTS.PROGRAM_MASTER,
  },
  {
    id: 4,
    icon: <IconUserTypeMaster width={18} height={18} />,
    text: "Platform User Type Master",
    path: ROUTE_CONSTANTS.PLATFORM_USER_TYPE_MASTER,
  },
  {
    id: 5,
    icon: <IconFacilitiesMaster width={18} height={18} />,
    text: "School Facilities Master",
    path: ROUTE_CONSTANTS.SCHOOL_FACILITIES_MASTER,
  },
  {
    id: 6,
    icon: <IconSubjectMaster width={18} height={18} />,
    text: "Subject Master",
    path: ROUTE_CONSTANTS.SUBJECT_MASTER,
  },
  {
    id: 7,
    icon: <IconGradeMaster width={18} height={18} />,
    text: "Grade Master",
    path: ROUTE_CONSTANTS.GRADE_MASTER,
  },
  {
    id: 8,
    icon: <IconMasterManagement width={18} height={18} />,
    text: "Master Management",
    path: ROUTE_CONSTANTS.MATER_MANAGEMENT,
  },
  {
    id: 8,
    icon: <IconRequestManagement width={18} height={18} />,
    text: "Request Management",
    path: ROUTE_CONSTANTS.REQUEST_MANAGEMENT,
  },
  {
    id: 9,
    icon: <IconBannerManagement width={18} height={18} />,
    text: "Banner Management",
    path: ROUTE_CONSTANTS.BANNER_MANAGEMENT,
  },
  {
    id: 9,
    icon: <IconSchoolTypeMaster width={18} height={18} />,
    text: "School Management",
    path: ROUTE_CONSTANTS.SCHOOL_MASTER,
  },
  {
    id: 10,
    icon: <IconSchoolTypeMaster width={18} height={18} />,
    text: "Popular Schools",
    path: ROUTE_CONSTANTS.POPULAR_SCHOOLS,
  },
  {
    id: 11,
    icon: <IconRequestManagement width={18} height={18} />,
    text: "Minister User Management",
    path: ROUTE_CONSTANTS.MINISTER_USER_MANAGEMENT,
  },
  {
    id: 12,
    icon: <IconRequestManagement width={18} height={18} />,
    text: "News Management",
    path: ROUTE_CONSTANTS.NEWS_MANAGEMENT,
  },
  {
    id: 13,
    icon: <IconRequestManagement width={18} height={18} />,
    text: "Article Management",
    path: ROUTE_CONSTANTS.ARTICLE_MANAGEMENT,
  },
];
