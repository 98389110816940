import React from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "hooks/useForm";
import MainLayout from "layouts/MainLayout";
import FormLayout from "layouts/FormLayout";
import { GridContainer, StyledFlex } from "constants/commonStyles";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";
import { FieldTypes } from "constants/fieldTypes";
import Input from "components/CustomInput";
import Dropdown from "components/CustomDropdown";
import CustomDatePicker from "components/CustomDatePicker";
import "./index.css";

const AddEditMinisterUserManagement = (props) => {
  const { formHeading } = props;
  const location = useLocation();
  const mode = location?.state?.mode;

  const initialState = {
    ministerName: {
      value: "Saint Marys",
      error: "",
    },
    ministerCycle: {
      value: "Secondary",
      error: "",
    },
    gender: {
      value: "Male",
      error: "",
    },
    emailId: {
      value: "demo@gmail.com",
      error: "",
    },
    region: {
      value: "Region",
      error: "",
    },
    province: {
      value: "Province",
      error: "",
    },
    commune: {
      value: "Commune",
      error: "",
    },
    contactInformation: {
      value: "8756890111",
      error: "",
    },
    registeredAt: {
      value: "2024-10-10",
      error: "",
    },
  };

  const onHandleMinisterUserSubmission = (params, error) => {
    if (mode) {
    } else {
    }
  };

  const {
    errors,
    dirty,
    handleOnSubmit,
    handleOnChange,
    handleOnSelection,
    values,
  } = useForm(initialState, onHandleMinisterUserSubmission);

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const commonInputProps = {
    onChangeHandler: handleOnChange,
    mode: mode,
    required: true,
    type: FieldTypes.TYPE_TEXT,
  };

  const commonDropDownProps = {
    placeholder: "Select",
    options: options,
    onChangeHandler: handleOnSelection,
    allowClear: true,
    mode: mode,
    required: true,
  };

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={"Cancel"}
        cancelBtnRoute={ROUTE_CONSTANTS.MINISTER_USER_MANAGEMENT}
        saveBtnText={"Save"}
        mode={location?.state?.mode}
        viewButtonText={"Edit"}
        viewFormEditRoute={ROUTE_CONSTANTS.VIEW_MINISTER_USER_MANAGEMENT}
      >
        <StyledFlex>
          <Input
            name="ministerName"
            placeholder="Minister Name"
            label={"Minister Name"}
            value={values?.ministerName}
            {...commonInputProps}
          />
          <Input
            name="emailId"
            placeholder="Email Id"
            label={"Email Id"}
            value={values?.emailId}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            fieldName={"ministerCycle"}
            label={"Select Minister Cycle"}
            onChangeHandler={handleOnSelection}
            value={values?.ministerCycle}
            placeholder="Select Minister Cycle"
            {...commonDropDownProps}
          />
          <Dropdown
            fieldName={"gender"}
            label={"Select Gender"}
            value={values?.gender}
            placeholder="Select Gender"
            {...commonDropDownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            fieldName={"region"}
            label={"Select Region"}
            onChangeHandler={handleOnSelection}
            value={values?.region}
            placeholder="Select Region"
            {...commonDropDownProps}
          />
          <Dropdown
            fieldName={"province"}
            label={"Select Province"}
            value={values?.province}
            placeholder="Select Province"
            {...commonDropDownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            fieldName={"commune"}
            label={"Select Commune"}
            onChangeHandler={handleOnSelection}
            value={values?.commune}
            placeholder="Select Commune"
            classes="abcd"
            {...commonDropDownProps}
          />
          <Input
            name="contactInformation"
            placeholder="Contact Information"
            label={"Contact Information"}
            value={values?.contactInformation}
            {...commonInputProps}
          />
        </StyledFlex>
        <GridContainer marginTop={"10"}>
          <CustomDatePicker
            fieldName={"registeredAt"}
            title={"Registered Date"}
            data={values?.registeredAt}
            placeholder={values?.registeredAt}
            hasError={errors.registeredAt && dirty.registeredAt}
            errorMessage={errors.registeredAt}
            required={true}
            hasIcon={true}
            onChangeHandler={handleOnChange}
            disableAutoFocus={true}
            className={"minister-user-date-width"}
            isDisabled={mode === STRING_CONSTANTS.COMMON.MODE.VIEW}
          />
        </GridContainer>
      </FormLayout>
    </MainLayout>
  );
};

export default AddEditMinisterUserManagement;
