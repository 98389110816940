import React from "react";
import MainLayout from "layouts/MainLayout";
import Input from "components/CustomInput";
import { useForm } from "hooks/useForm";
import Textarea from "components/CustomTextarea";
import FormLayout from "layouts/FormLayout";
import { useLocation } from "react-router-dom";
import { StyledFlex } from "constants/commonStyles";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";
import { FieldTypes } from "constants/fieldTypes";
import "./index.css";

const PlatformUserTypeMasterForm = ({ formHeading }) => {
  const location = useLocation();
  const mode = location?.state?.mode;

  const initialState = {
    userTypeInEnglish: {
      value: "sasa",
      error: "",
    },
    name: {
      value: "riddhi",
      error: "",
    },
    descriptionInEnglish: {
      value:
        "ong established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal",
      error: "",
    },
    descriptionInFrench: {
      value:
        "ong established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal",
      error: "",
    },
  };

  const onSubmitPlatformUserTypeMaster = (params, error) => {};

  const { errors, handleOnSubmit, handleOnChange, values } = useForm(
    initialState,
    onSubmitPlatformUserTypeMaster
  );

  const commonInputProps = {
    type: FieldTypes.TYPE_TEXT,
    onChangeHandler: handleOnChange,
    mode: mode,
    required: true,
  };

  const commonTextAreaProps = {
    type: FieldTypes.TYPE_TEXT,
    placeholder: "Add Description.",
    onChangeHandler: handleOnChange,
    mode: mode,
    textareaClass: "platofrm-user-type-master-textarea-content",
    required: true,
    rows: "1",
    cols: "2",
  };

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={"Cancel"}
        cancelBtnRoute={"/platform-user-type-master"}
        saveBtnText={"Save"}
        mode={mode}
        viewButtonText={"Edit"}
        viewFormEditRoute={ROUTE_CONSTANTS.EDIT_PLATFORM_USER_TYPE_MASTER}
      >
        <StyledFlex>
          <Input
            name="userTypeInEnglish"
            placeholder="User Type"
            label={"User Type" + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values.userTypeInEnglish}
            {...commonInputProps}
          />
          <Input
            name="userTypeInFrench"
            placeholder="User Type"
            label={"User Type" + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values.userTypeInFrench}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Input
            name="nameInEnglish"
            placeholder="Name"
            label={"Name" + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values.nameInEnglish}
            {...commonInputProps}
          />
          <Input
            name="nameInFrench"
            placeholder="Name"
            label={"Name" + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values.nameInFrench}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Textarea
            name="descriptionInEnglish"
            label={"Add Description." + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values.descriptionInEnglish}
            {...commonTextAreaProps}
          />
          <Textarea
            name="descriptionInFrench"
            label={"Add Description." + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values.descriptionInFrench}
            {...commonTextAreaProps}
          />
        </StyledFlex>
      </FormLayout>
    </MainLayout>
  );
};

export default PlatformUserTypeMasterForm;
