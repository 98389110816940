import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MENULIST } from "constants/sidenavMenuItemList";
import { IconEdulacLogo, IconEdulacSymbol } from "assets/images/SVGs";
import "./index.css";

const SideNav = ({ isCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname;

  return (
    <div className={`sidebar-main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
      <div className="side-nav-logo">
        <p>{isCollapsed ? <IconEdulacSymbol /> : <IconEdulacLogo />}</p>
      </div>
      <div className="sidebar-list">
        {MENULIST?.map((menuItem, index) => {
          const isActive = currentRoute === menuItem.path;
          return (
            <li
              onClick={() => navigate(menuItem.path)}
              key={`side_nav_${index}`}
              className={`sidebar-item ${isActive ? "active" : ""}`}
            >
              <div
                className={`sidenav-menu-div ${isCollapsed ? "collapsed" : ""}`}
              >
                <div
                  className={`icon-wrapper ${isCollapsed ? "collapsed" : ""}`}
                >
                  {menuItem?.icon}
                </div>
                <span
                  className={`sidenav-menu-item-span ${
                    isCollapsed ? "collapsed" : ""
                  }`}
                >
                  {menuItem?.text}
                </span>
              </div>
            </li>
          );
        })}
      </div>
    </div>
  );
};

export default SideNav;
