import React from "react";
import { useLocation } from "react-router-dom";
import FormLayout from "layouts/FormLayout";
import MainLayout from "layouts/MainLayout";
import { useForm } from "hooks/useForm";
import Dropdown from "components/CustomDropdown";
import Input from "components/CustomInput";
import Textarea from "components/CustomTextarea";
import { StyledFlex } from "constants/commonStyles";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";
import { FieldTypes } from "constants/fieldTypes";
import "./index.css";

const SchoolFacilitiesForm = ({ formHeading }) => {
  const location = useLocation();
  const mode = location?.state?.mode;

  const initialState = {
    facilityNameInEnglish: {
      value: "facility name",
      error: "",
    },
    facilityNameInFrench: {
      value: "facility name",
      error: "",
    },
    descriptionInEnglish: {
      value:
        "established fact that a reader will be distracted by the readable content ",
      error: "",
    },
    descriptionInFrench: {
      value:
        "established fact that a reader will be distracted by the readable content ",
      error: "",
    },
    schoolCategory: {
      value: "Cycle 1",
      error: "",
    },
    schoolStatus: {
      value: "Active",
      error: "",
    },
    type: {
      value: "Formal Education",
      error: "",
    },
  };

  const handleGradeMasterSubmission = (params, error) => {
    if (mode) {
    } else {
    }
  };

  const { errors, handleOnSubmit, handleOnChange, values, handleOnSelection } =
    useForm(initialState, handleGradeMasterSubmission);

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const commonInputProps = {
    type: FieldTypes.TYPE_TEXT,
    placeholder: "Add Facility Name",
    onChangeHandler: handleOnChange,
    mode,
    required: true,
  };

  const commonTextAreaProps = {
    type: FieldTypes.TYPE_TEXT,
    placeholder: "Add Description.",
    onChangeHandler: handleOnChange,
    mode,
    rows: "2",
    cols: "2",
    required: true,
    textareaClass: "school-faciliites-textarea-content",
  };

  const commonDropDownProps = {
    options,
    placeholder: "Select",
    onChangeHandler: handleOnSelection,
    allowClear: true,
    mode,
    required: true,
  };

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={"Cancel"}
        cancelBtnRoute={ROUTE_CONSTANTS.SCHOOL_FACILITIES_MASTER}
        saveBtnText={"Save"}
        mode={mode}
        viewButtonText={"Edit"}
        viewFormEditRoute={ROUTE_CONSTANTS.EDIT_SCHOOL_FACILITIES}
      >
        <StyledFlex>
          <Input
            name="facilityNameInEnglish"
            label={"Add Facility Name" + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values.facilityNameInEnglish}
            {...commonInputProps}
          />
          <Input
            name="facilityNameInFrench"
            label={"Add Facility Name" + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values.facilityNameInFrench}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Textarea
            name="descriptionInEnglish"
            label={"Add Description." + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values.descriptionInEnglish}
            {...commonTextAreaProps}
          />
          <Textarea
            name="descriptionInFrench"
            label={"Add Description." + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values.descriptionInFrench}
            {...commonTextAreaProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            fieldName={"schoolCategory"}
            label={"Select School Category"}
            placeholder="Select School Category"
            value={values.schoolCategory}
            {...commonDropDownProps}
          />
          <Dropdown
            fieldName={"schoolStatus"}
            label={"Select School Status"}
            placeholder="Select School Status"
            value={values.schoolStatus}
            {...commonDropDownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            fieldName={"type"}
            label={"Select School Education Type"}
            placeholder="Select School Education Type"
            value={values.type}
            {...commonDropDownProps}
          />
        </StyledFlex>
      </FormLayout>
    </MainLayout>
  );
};

export default SchoolFacilitiesForm;
