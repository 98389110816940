import React from "react";
import { useLocation } from "react-router-dom";
import CustomDropdown from "components/CustomDropdown";
import CustomInput from "components/CustomInput";
import CustomTextarea from "components/CustomTextarea";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";
import { StyledFlex } from "constants/commonStyles";
import { useForm } from "hooks/useForm";
import FormLayout from "layouts/FormLayout";
import MainLayout from "layouts/MainLayout";
import { FieldTypes } from "constants/fieldTypes";
import "./index.css";

const GradeMasterForm = ({ formHeading }) => {
  const location = useLocation();
  const mode = location?.state?.mode;

  const initialState = {
    gradeNameInEnglish: {
      value: "grade in english",
      error: "",
    },
    gradeNameInFrench: {
      value: "grade in french",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
    descriptionInEnglish: {
      value:
        "ong established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal",
      error: "",
    },
    descriptionInFrench: {
      value:
        "ong established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal",
      error: "",
    },
    schoolCategory: {
      value: "Cycle 1",
      error: "",
    },
    schoolStatus: {
      value: "Active",
      error: "",
    },
    schoolEducationType: {
      value: "Formal Education",
      error: "",
    },
  };

  const handleGradeMasterSubmission = (params, error) => {
    if (mode) {
    } else {
    }
  };

  const { errors, handleOnSubmit, handleOnChange, values, handleOnSelection } =
    useForm(initialState, handleGradeMasterSubmission);

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const commonInputProps = {
    type: FieldTypes.TYPE_TEXT,
    placeholder: "Grade Name",
    onChangeHandler: handleOnChange,
    mode: mode,
    required: true,
  };

  const commonTextAreaProps = {
    type: FieldTypes.TYPE_TEXT,
    placeholder: "Add Description.",
    onChangeHandler: handleOnChange,
    mode,
    rows: "2",
    cols: "2",
    required: true,
    textareaClass: "grade-master-textarea-content",
  };

  const commonDropdownProps = {
    options: options,
    allowClear: true,
    mode: mode,
    onChangeHandler: handleOnSelection,
    required: true,
  };

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={"Cancel"}
        cancelBtnRoute={ROUTE_CONSTANTS.GRADE_MASTER}
        saveBtnText={"Save"}
        mode={mode}
        viewButtonText={"Edit"}
        viewFormEditRoute={ROUTE_CONSTANTS.EDIT_GRADE_MASTER}
      >
        <StyledFlex>
          <CustomInput
            name="gradeNameInEnglish"
            onChangeHandler={handleOnChange}
            label={"Grade Name" + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            mode={mode}
            value={values.gradeNameInEnglish}
            {...commonInputProps}
          />
          <CustomInput
            name="gradeNameInFrench"
            onChangeHandler={handleOnChange}
            label={"Grade Name" + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values.gradeNameInFrench}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomTextarea
            name="descriptionInEnglish"
            label={"Add Description." + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values.descriptionInEnglish}
            {...commonTextAreaProps}
          />
          <CustomTextarea
            name="descriptionInFrench"
            label={"Add Description." + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values.descriptionInFrench}
            {...commonTextAreaProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            fieldName={"schoolCategory"}
            label={"Category (Cycle)"}
            placeholder="Category (Cycle)"
            value={values.schoolCategory}
            {...commonDropdownProps}
          />
          <CustomDropdown
            fieldName={"schoolType"}
            label={"Select School Type"}
            placeholder="Select School Type"
            value={values.schoolType}
            {...commonDropdownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            fieldName={"schoolStatus"}
            label={"School/University/Institute Status"}
            placeholder="School/University/Institute Status"
            value={values.schoolStatus}
            {...commonDropdownProps}
          />
        </StyledFlex>
      </FormLayout>
    </MainLayout>
  );
};

export default GradeMasterForm;
