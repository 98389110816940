import React from "react";
import { useLocation } from "react-router-dom";
import MainLayout from "layouts/MainLayout";
import FormLayout from "layouts/FormLayout";
import { GridContainer, StyledFlex } from "constants/commonStyles";
import { useForm } from "hooks/useForm";
import { FieldTypes } from "constants/fieldTypes";
import Input from "components/CustomInput";
import Dropdown from "components/CustomDropdown";
import CustomDatePicker from "components/CustomDatePicker";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";
import CustomFileInput from "components/CustomFileInput";
import CustomSwitch from "components/CustomSwitch";
import "./index.css";

const AddEditSchoolMaster = (props) => {
  const { formHeading } = props;
  const location = useLocation();
  const mode = location?.state?.mode;

  const initialState = {
    schoolName: {
      value: "Saint Marys",
      error: "",
      required: true,
    },
    schoolCycle: {
      value: "Pre-primary",
      error: "",
    },
    region: {
      value: "Region",
      error: "",
      required: true,
    },
    province: {
      value: "Province",
      error: "",
    },
    commune: {
      value: "Commune",
      error: "",
    },
    schoolStatus: {
      value: "Enabled",
      error: "",
    },
    schoolType: {
      value: "Multipurpose",
      error: "",
    },
    schoolEducationType: {
      value: "Medical",
      error: "",
    },
    partnerShipType: {
      value: "Conventional",
      error: "",
    },
    administrativeStatus: {
      value: "Recognized",
      error: "",
    },
    address: {
      value: "Formal Education",
      error: "",
    },
    contactInfo: {
      value: "0987890987",
      error: "",
    },
    registeredDate: {
      value: "2024-10-04",
      error: "",
    },
    emailId: {
      value: "demo!gmail.com",
      error: "",
    },
    phoneNumber: {
      value: "8978906789",
      error: "",
    },
    idProof: {
      value: "",
      error: "",
    },
    passport: {
      value: "",
      error: "",
    },
    legalDocumentImage: {
      value: "",
      error: "",
    },
    isTransportationService: {
      value: false,
      error: "",
    },
    isMedicalFacilities: {
      value: false,
      error: "",
    },
    isDinningFacilities: {
      value: false,
      error: "",
    },
    isPlayGround: {
      value: false,
      error: "",
    },
    isScienceLab: {
      value: false,
      error: "",
    },
    isLibrary: {
      value: false,
      error: "",
    },
    isComputer: {
      value: false,
      error: "",
    },
    isSportFacility: {
      value: false,
      error: "",
    },
  };

  const handleSchoolMasterSubmission = (params, error) => {
    if (mode) {
    } else {
    }
  };

  const {
    errors,
    dirty,
    handleOnSubmit,
    handleOnChange,
    handleOnSelection,
    handleOnSwitch,
    values,
  } = useForm(initialState, handleSchoolMasterSubmission);

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const commonInputProps = {
    onChangeHandler: handleOnChange,
    mode: mode,
    required: true,
    type: FieldTypes.TYPE_TEXT,
  };

  const commonDropDownProps = {
    placeholder: "Select",
    options: options,
    onChangeHandler: handleOnSelection,
    allowClear: true,
    mode: mode,
    required: true,
  };

  const commonSwitchProps = {
    onChangeHandler: handleOnSwitch,
    mode: mode,
  };

  const commonFileInputProps = {
    accepts: "image/png, image/jpeg, image/jpg",
    isDragAndDropRequired: true,
    disableAutoFocus: true,
    required: true,
    onChangeHandler: () => {},
  };

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={"Cancel"}
        cancelBtnRoute={ROUTE_CONSTANTS.SCHOOL_MASTER}
        saveBtnText={"Save"}
        mode={location?.state?.mode}
        viewButtonText={"Edit"}
        viewFormEditRoute={ROUTE_CONSTANTS.EDIT_SCHOOL_MASTER}
      >
        <StyledFlex>
          <Input
            name="schoolName"
            placeholder="School Name"
            label={"School Name"}
            value={values?.schoolName}
            {...commonInputProps}
          />
          <Input
            name="contactInfo"
            placeholder="Contact Number"
            label={"Contact Number"}
            value={values?.contactInfo}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            fieldName={"schoolCycle"}
            label={"Select School Cycle"}
            onChangeHandler={handleOnSelection}
            value={values?.schoolCycle}
            placeholder="Select School Cycle"
            {...commonDropDownProps}
          />
          <Dropdown
            fieldName={"schoolStatus"}
            label={"Select School Status"}
            value={values?.schoolStatus}
            placeholder="Select School Status"
            {...commonDropDownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            fieldName={"schoolType"}
            label={"Select School Type"}
            onChangeHandler={handleOnSelection}
            value={values?.schoolType}
            placeholder="Select School Type"
            {...commonDropDownProps}
          />
          <Dropdown
            fieldName={"schoolEducationType"}
            label={"Select Education Type"}
            value={values?.schoolEducationType}
            placeholder="Select Education Type"
            {...commonDropDownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            fieldName={"partnerShipType"}
            label={"Select Partner Ship Type"}
            onChangeHandler={handleOnSelection}
            value={values?.partnerShipType}
            placeholder="Select Partner Ship type"
            {...commonDropDownProps}
          />
          <Dropdown
            fieldName={"administrativeStatus"}
            label={"Select Administrative Status"}
            value={values?.administrativeStatus}
            placeholder="Select Administrative Status"
            {...commonDropDownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            fieldName={"region"}
            label={"Select Region"}
            onChangeHandler={handleOnSelection}
            value={values?.region}
            placeholder="Select Region"
            {...commonDropDownProps}
          />
          <Dropdown
            fieldName={"province"}
            label={"Select Province"}
            value={values?.province}
            placeholder="Select Province"
            {...commonDropDownProps}
          />
        </StyledFlex>
        <GridContainer marginTop={"10"}>
          <Dropdown
            fieldName={"commune"}
            label={"Select Commune"}
            value={values?.commune}
            placeholder="Select Commune"
            classes={"school-master-commune-ddl"}
            {...commonDropDownProps}
          />
          <CustomDatePicker
            fieldName={"registeredDate"}
            title={"Registered Date"}
            data={values?.registeredDate}
            placeholder={values?.registeredDate}
            hasError={errors.registeredDate && dirty.registeredDate}
            errorMessage={errors.registeredDate}
            required={true}
            hasIcon={true}
            onChangeHandler={handleOnChange}
            className={"school-master-registered-date"}
            disableAutoFocus={true}
            isDisabled={mode === STRING_CONSTANTS.COMMON.MODE.VIEW}
          />
        </GridContainer>
        {mode === STRING_CONSTANTS.COMMON.MODE.VIEW ? (
          <>
            <GridContainer marginTop={"10"}>
              <p className="school-master-field-set-name">
                {STRING_CONSTANTS.SCHOOL_MASTER.FIELDSET_PERSONAL_INFORMATION}
              </p>
            </GridContainer>
            <StyledFlex>
              <Input
                name="schoolName"
                placeholder="Email Id"
                label={"Email Id"}
                value={values?.emailId}
                {...commonInputProps}
              />
              <Input
                name="contactInfo"
                placeholder="Phone Number"
                label={"Phone Number"}
                value={values?.phoneNumber}
                {...commonInputProps}
              />
            </StyledFlex>
            <GridContainer marginTop="14">
              <CustomFileInput
                fieldName={"idProof"}
                title={"Id Proof"}
                description={"Upload An Id Proof Image"}
                hasPreview={values?.idProof}
                preview={values?.idProof}
                {...commonFileInputProps}
              />
              <CustomFileInput
                fieldName={"passport"}
                title={"Passport"}
                description={"Upload An Passport Image"}
                hasPreview={values?.passport}
                preview={values?.passport}
                {...commonFileInputProps}
              />
            </GridContainer>
            <GridContainer marginTop={"10"}>
              <CustomFileInput
                fieldName={"legalDocumentImage"}
                title={"Legal Document Image"}
                description={"Upload An Legal Documnet Image"}
                hasPreview={values?.legalDocumentImage}
                preview={values?.legalDocumentImage}
                className="school-master-legal-document-image"
                {...commonFileInputProps}
              />
            </GridContainer>
            <GridContainer marginTop={"10"}>
              <p className="school-master-field-set-name">
                {STRING_CONSTANTS.SCHOOL_MASTER.FIELDSET_FACILITY_INFORMATION}
              </p>
            </GridContainer>
            <GridContainer marginTop={"10"}>
              <CustomSwitch
                fieldName={"isTransportationService"}
                title={"Transportation Services"}
                isChecked={!values?.isTransportationService}
                {...commonSwitchProps}
              />
              <CustomSwitch
                fieldName={"isMedicalFacilities"}
                title={"Medical Facilities"}
                isChecked={!values?.isMedicalFacilities}
                {...commonSwitchProps}
              />
            </GridContainer>
            <GridContainer marginTop={"10"}>
              <CustomSwitch
                fieldName={"isDinningFacilities"}
                title={"Dining Facilities"}
                isChecked={!values?.isDinningFacilities}
                {...commonSwitchProps}
              />
              <CustomSwitch
                fieldName={"isPlayGround"}
                title={"Playground"}
                isChecked={!values?.isPlayGround}
                {...commonSwitchProps}
              />
            </GridContainer>
            <GridContainer marginTop={"10"}>
              <CustomSwitch
                fieldName={"isScienceLab"}
                title={"Science Lab"}
                isChecked={!values?.isScienceLab}
                {...commonSwitchProps}
              />
              <CustomSwitch
                fieldName={"isLibrary"}
                title={"Library"}
                isChecked={!values?.isLibrary}
                {...commonSwitchProps}
              />
            </GridContainer>
            <GridContainer marginTop={"10"}>
              <CustomSwitch
                fieldName={"isComputer"}
                title={"Computers For Students"}
                isChecked={!values?.isComputer}
                {...commonSwitchProps}
              />
              <CustomSwitch
                fieldName={"isSportFacility"}
                title={"Available Sport Facility"}
                isChecked={!values?.isSportFacility}
                {...commonSwitchProps}
              />
            </GridContainer>
          </>
        ) : null}
      </FormLayout>
    </MainLayout>
  );
};

export default AddEditSchoolMaster;
