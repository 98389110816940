import React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import "./index.css";
import _ from "lodash";
import { Pagination } from "../CustomPagination";
import CustomInput from "components/CustomInput";
import {
  IconFilter,
  IconSortGrey,
  IconTableCollapseMinus,
  IconTableCollapsePlus,
} from "assets/images/SVGs";
import { NoDataToDisplay } from "components/CustomNoDataToDisplay";
import { FieldTypes } from "constants/fieldTypes";
import { ASC, DESC, STRING_CONSTANTS } from "constants/common";
import TableHeader from "components/TableHeader";
import CustomTableStyled from "./customTableStyles";
import CustomButton from "components/CustomButton";

const { FilterDiv, StyledTableBody, CustomTableWrapper } = CustomTableStyled;

export function CustomTable({
  isPagination = true,
  isTab,
  data = null,
  columns,
  count,
  filterOptions,
  onChangeData,
  loader = false,
  width,
  footerData,
  class: className,
  isCollapsibleTable = false,
  collasedRowDetailsToShow,
  instanceId,
  clearChildSearchField,
  clearChildSearchHandler,
  isFromCandidateApplication,
  outerTableClass = "",
  restrictSpecialCharsOnSearch = true,
  headerRowClass = "",
  hasSearch = true,
  getId,
  isCheckboxDisabled = false,
  disableHeaderCheckbox = false,
  showEntries = true,
  numberOfEntriesToShow = 10,
  searchOnEnterKeyPress = true,
  noDataFoundMessage,
  handleIsFilter,
  isFilter,
  isHandleAddRoute,
  tableHeader,
  addBtnText,
  handleAddRoute,
  showAddButton,
  btnMarginTop,
  showExportButton,
  childCheckBoxChecked,
  setChildCheckBoxChecked,
  setCheckedState = () => {},
  showPopular,
  showDuration,
  ...props
}) {
  const ref = useRef();
  const searchRef = useRef();
  const [searchData, setSearchData] = useState("");
  const [sortVal, setSortVal] = useState(true);
  const [rowData, setRowData] = useState(data);
  const [parentCheckBoxValue, setParentCheckBoxValue] = useState(false);
  const [page, setPage] = useState(1);

  const [dataToParent, setDataToParent] = useState({
    selectedCheckBoxData: [],
    sortBy: ASC,
    sortByKeyName: "",
    searchedData: "",
    numberOfEntriesToShow,
    page: page,
  });

  const numberOfEntries = [10, 20, 30, 40, 50];

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && searchOnEnterKeyPress) {
        handleOnChangeSearch(event?.target?.value);
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useMemo(() => {
    if (clearChildSearchField) {
      setSearchData("");
      clearChildSearchHandler();
      searchRef?.current?.clearSearchField("");
    }
  }, [instanceId]);

  useEffect(() => {
    if (count !== ref.current) {
      setPage(1);
      setParentCheckBoxValue(false);
    }
    ref.current = count;
    setRowData(data);
  }, [data]);

  useEffect(() => {
    if (props?.page) {
      setPage(parseInt(props?.page, 10));
    }
  }, [props?.page]);

  const allCommonChangeHandler = (cloneFields, isSetPage, pageToSet) => {
    setDataToParent({ ...cloneFields });
    onChangeData({ ...cloneFields });

    if (isSetPage) {
      setPage(parseInt(pageToSet, 10));
    }
  };

  const onPageChangeHandler = (pageNumber) => {
    if (!loader) {
      let cloneFields = _.cloneDeep(dataToParent);
      cloneFields = {
        ...cloneFields,
        selectedCheckBoxData: [],
        page: pageNumber,
      };
      allCommonChangeHandler(cloneFields, true, pageNumber);
      setParentCheckBoxValue(false);
    }
  };

  const handleOnChangeSearch = (searchInput) => {
    let cloneFields = _.cloneDeep(dataToParent);

    cloneFields = {
      ...cloneFields,
      searchedData: searchInput,
      page: 1,
    };
    setSearchData(searchInput);
    allCommonChangeHandler(cloneFields, true, 1);
  };

  const handleSortChange = (keyName) => {
    setSortVal(!sortVal);

    let cloneFields = _.cloneDeep(dataToParent);

    cloneFields = {
      ...cloneFields,
      sortBy: sortVal ? ASC : DESC,
      sortByKeyName: keyName,
      page: 1,
    };
    allCommonChangeHandler(cloneFields, true, 1);
  };

  const handleCheckBoxChange = (e, index, id) => {
    let cloneFieldsForCheck = _.cloneDeep(rowData);
    let cloneFields = _.cloneDeep(dataToParent);

    cloneFieldsForCheck[index]["checked"] = e?.target?.checked;
    let allCheckedValues = cloneFieldsForCheck?.map((ele) => ele?.checked);

    if (allCheckedValues?.includes(false)) {
      setParentCheckBoxValue(false);
    } else {
      setParentCheckBoxValue(true);
    }

    if (e?.target?.checked) {
      cloneFields["selectedCheckBoxData"].push(id);
    } else {
      cloneFields["selectedCheckBoxData"] = cloneFields[
        "selectedCheckBoxData"
      ]?.filter((id1) => id1 !== id);
    }
    if (getId) {
      getId(id, "childCheck");
    }
    allCommonChangeHandler(cloneFields, false, 1);
    setRowData(cloneFieldsForCheck);
  };

  const rowCollapseChange = (index) => {
    let dataToModify = _.cloneDeep(rowData);
    dataToModify[index].isCollapse = !dataToModify[index].isCollapse;
    setRowData(dataToModify);
  };

  const handleParentCheckBoxChange = (e) => {
    const isChecked = e?.target?.checked;
    setCheckedState(new Array(data?.length).fill(isChecked));
    setChildCheckBoxChecked(!childCheckBoxChecked);
  };

  const renderButton = (show, btnText, onClickHandler) => {
    return show ? (
      <div className="search-btn-align-content">
        <CustomButton
          btnText={btnText}
          onClickHandler={onClickHandler}
          btnMarginTop
          width={100}
          title={btnText}
        />
      </div>
    ) : null;
  };

  return (
    <CustomTableWrapper>
      <TableHeader
        tableHeader={tableHeader}
        showAddButton={showAddButton}
        addBtnText={addBtnText}
        handleAddRoute={handleAddRoute}
        btnMarginTop={btnMarginTop}
      />

      <div className={`outer_table_class ${outerTableClass}`}>
        {showEntries || hasSearch ? (
          <>
            <div className="table_upper_div_entries_search">
              <div className="search-align-actions-main">
                <div className="table_search_div mr-4">
                  <CustomInput
                    placeholder={"Search"}
                    type="text"
                    disableAutoFocus={true}
                  />
                </div>
                <div className="search-btn-align-content">
                  <CustomButton
                    btnText={STRING_CONSTANTS.COMMON.BUTTON.SEARCH_TITLE}
                    onClickHandler={() => {}}
                    btnMarginTop
                    width={100}
                    title={STRING_CONSTANTS.COMMON.BUTTON.SEARCH_TITLE}
                  />
                </div>
                <div className="search-btn-align-content">
                  <CustomButton
                    btnText={STRING_CONSTANTS.COMMON.BUTTON.RESET_TITLE}
                    onClickHandler={() => {}}
                    btnMarginTop
                    width={100}
                    title={STRING_CONSTANTS.COMMON.BUTTON.RESET_TITLE}
                  />
                </div>
                {renderButton(
                  showExportButton,
                  STRING_CONSTANTS.COMMON.BUTTON.EXPORT_TITLE,
                  () => {}
                )}
                {renderButton(
                  showPopular,
                  STRING_CONSTANTS.COMMON.BUTTON.SET_AS_POPULAR_TITLE,
                  () => {}
                )}
                {renderButton(
                  showDuration,
                  STRING_CONSTANTS.COMMON.BUTTON.SET_AS_DUARATION_TITLE,
                  () => {}
                )}
              </div>

              <FilterDiv isFilter onClick={handleIsFilter} title="Filter">
                <IconFilter width={18} height={18} />
              </FilterDiv>
            </div>
            {isFilter ? filterOptions : null}
          </>
        ) : null}

        {!loader ? (
          rowData?.length > 0 ? (
            <div
              className={`table_cover_div ${
                !showEntries && !hasSearch
                  ? "table_cover_div_no_entries_search"
                  : ""
              }`}
            >
              {isTab ? null : (
                <table
                  className="px-4 custom_table"
                  style={{
                    width: width ? width + "%" : "100%",
                  }}
                  id="table"
                >
                  <thead className="table_header">
                    <tr>
                      {columns?.length > 0
                        ? columns.map((head, i) => (
                            <th
                              key={i.toString()}
                              className={
                                i === columns.length - 1
                                  ? "fixed-action-header"
                                  : ""
                              }
                              style={{
                                width: head?.customHeaderWidth
                                  ? head?.customHeaderWidth + "%"
                                  : "",
                              }}
                            >
                              <div className="table_heading">
                                {head?.actionInField &&
                                !disableHeaderCheckbox ? (
                                  <div className="action_field_checkbox">
                                    <input
                                      type="checkbox"
                                      checked={childCheckBoxChecked}
                                      onChange={handleParentCheckBoxChange}
                                    />
                                  </div>
                                ) : (
                                  <span className="table_header_name_icon">
                                    <span className="table_header_span">
                                      {head?.header}
                                    </span>
                                    {head?.enableSort ? (
                                      <span
                                        onClick={() =>
                                          handleSortChange(head?.field)
                                        }
                                        className="table_header_sort_icon"
                                      >
                                        <IconSortGrey color={"#cecece"} />
                                      </span>
                                    ) : null}
                                  </span>
                                )}
                              </div>
                            </th>
                          ))
                        : null}
                    </tr>
                  </thead>
                  <StyledTableBody>
                    {rowData?.length > 0
                      ? rowData?.map((row, parentIndex) => (
                          <>
                            <tr>
                              {columns?.map((col, index) => (
                                <td
                                  className={`position-relative ${
                                    col?.tdClass ?? ""
                                  } ${
                                    index === columns.length - 1
                                      ? "fixed-action-column"
                                      : ""
                                  }`}
                                  key={index}
                                >
                                  <span
                                    className={`px-2 mx-3 ${
                                      isFromCandidateApplication
                                        ? "max_col_width_candidate_application"
                                        : "max_col_width"
                                    } ${col.upperClass || ""}`}
                                  >
                                    {col?.shouldRenderElement ? (
                                      col.renderElement(
                                        row[col?.field],
                                        parentIndex,
                                        row
                                      )
                                    ) : col?.actionInField ? (
                                      <div className="action_field_checkbox">
                                        <CustomInput
                                          data={index}
                                          fieldName={index}
                                          type={FieldTypes.TYPE_CHECKBOX}
                                          checked={row?.checked}
                                          disabled={!row?.checked}
                                          onChangeHandler={(e) =>
                                            handleCheckBoxChange(
                                              e,
                                              parentIndex,
                                              row?.order ?? row?._id
                                            )
                                          }
                                          disableAutoFocus={true}
                                        />
                                      </div>
                                    ) : col?.icons ? (
                                      <div className="d-flex align-items-center">
                                        {col?.icons.map((singleIcon, i) => (
                                          <span
                                            key={i.toString()}
                                            className={`action_icons ${col.class}`}
                                            onClick={() =>
                                              singleIcon?.onClick(
                                                row?._id,
                                                "",
                                                parentIndex,
                                                row
                                              )
                                            }
                                          >
                                            {singleIcon.icon}
                                          </span>
                                        ))}
                                      </div>
                                    ) : col?.colForCollapse &&
                                      isCollapsibleTable ? (
                                      <div className="d-flex align-items-center">
                                        {col?.colForCollapse.map(
                                          (singleIcon, i) => (
                                            <span
                                              key={i.toString()}
                                              className={`action_icons ${singleIcon.class}`}
                                              onClick={() =>
                                                rowCollapseChange(parentIndex)
                                              }
                                            >
                                              {!row?.isCollapse ? (
                                                <span className="table_collapse_plus_icon">
                                                  <IconTableCollapsePlus />
                                                </span>
                                              ) : (
                                                <span className="table_collapse_minus_icon">
                                                  <IconTableCollapseMinus />
                                                </span>
                                              )}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    ) : col?.rating ? (
                                      ""
                                    ) : col?.status ? (
                                      <span className="fs-14 column_data">
                                        {col?.statusColorsRequired &&
                                        row[col?.field] ? (
                                          <span className="text-success">
                                            {col?.statusSuccessText}
                                          </span>
                                        ) : (
                                          <span className="text-danger">
                                            {col?.statusDangerText}
                                          </span>
                                        )}
                                      </span>
                                    ) : (
                                      <span
                                        className={`fs-14 column_data  ${
                                          col.class
                                        } ${
                                          col?.customAlignment
                                            ? col?.classesRequiredForCustomAlignment
                                            : ""
                                        }`}
                                        title={row[col.field]}
                                      >
                                        {row[col.field]?.length > 0 ||
                                        row[col.field] >= 0
                                          ? row[col.field]
                                          : "-"}
                                      </span>
                                    )}
                                  </span>
                                </td>
                              ))}
                            </tr>

                            {isCollapsibleTable && row?.isCollapse ? (
                              <tr className="border-0">
                                <td colSpan={9}>
                                  {collasedRowDetailsToShow(row, parentIndex)}
                                </td>
                              </tr>
                            ) : null}
                          </>
                        ))
                      : null}
                  </StyledTableBody>
                </table>
              )}
            </div>
          ) : null
        ) : null}
        {rowData?.length > 0 ? null : (
          <div className="text-center">
            <NoDataToDisplay message={noDataFoundMessage} />
          </div>
        )}
      </div>

      {isPagination ? (
        <div className="table_footer">
          <div className="m-0 p-0 ml-2 table_pagination_footer">
            <Pagination
              currentPage={page}
              totalRecords={count}
              perPage={dataToParent?.numberOfEntriesToShow ?? 20}
              pageChangeHandler={onPageChangeHandler}
              className={className}
              disabled={loader}
            />
          </div>
          <div className="bottom_parent_button">
            {footerData ? footerData() : null}
          </div>
        </div>
      ) : null}
    </CustomTableWrapper>
  );
}
