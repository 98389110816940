import React from "react";
import { useForm } from "hooks/useForm";
import MainLayout from "layouts/MainLayout";
import FormLayout from "layouts/FormLayout";
import { GridContainer, StyledFlex } from "constants/commonStyles";
import { FieldTypes } from "constants/fieldTypes";
import CustomInput from "components/CustomInput";
import CustomDropdown from "components/CustomDropdown";
import CustomFileInput from "components/CustomFileInput";
import {
  REGEX_STRINGS,
  ROUTE_CONSTANTS,
  STRING_CONSTANTS,
} from "constants/common";
import { getNewsSchema } from "./NewsUtils";
import { getLanguages } from "constants/utilities";
import "./index.css";

const AddEditNewsManagement = (props) => {
  const { formHeading, mode } = props;
  const languages = getLanguages(STRING_CONSTANTS.COMMON.LANGUAGES_LIST);
  const initialState = getNewsSchema(languages);

  const handleNewManagementSubmission = (params, error) => {
    if (mode) {
    } else {
    }
  };

  const {
    values,
    errors,
    dirty,
    handleOnSubmit,
    handleOnChange,
    handleOnSelection,
  } = useForm(initialState, handleNewManagementSubmission);

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const callUploadApi = async (fileData) => {
    const fileSelected = fileData;
    const sizeMB = parseFloat(fileSelected.size / (1024 * 1024)).toFixed(2);
    if (!fileSelected.name.match(REGEX_STRINGS.COMMON.JPEG)) {
      return;
    }
    if (sizeMB > 5) {
      return;
    }
  };

  const commonInputProps = {
    type: FieldTypes.TYPE_TEXT,
    onChangeHandler: handleOnChange,
    disableAutoFocus: true,
    required: true,
    mode: mode,
  };

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={STRING_CONSTANTS.COMMON.BUTTON.CANCEL_TITLE}
        cancelBtnRoute={ROUTE_CONSTANTS.NEWS_MANAGEMENT}
        saveBtnText={STRING_CONSTANTS.COMMON.BUTTON.SAVE_TITLE}
        mode={mode}
        viewButtonText={STRING_CONSTANTS.COMMON.BUTTON.EDIT_TITLE}
        viewFormEditRoute={ROUTE_CONSTANTS.EDIT_NEWS}
      >
        {languages?.length > 0
          ? languages?.map((language) => (
              <div key={language?.languageCode}>
                <StyledFlex>
                  <CustomInput
                    name={`newsTitle-${language?.languageCode}`}
                    placeholder={`News Title (${language?.name})`}
                    label={`News Title (${language?.name})`}
                    value={values?.[language?.languageCode]?.newsTitle?.value}
                    hasError={
                      errors[language?.languageCode]?.newsTitle &&
                      dirty[language?.languageCode]?.newsTitle
                    }
                    errorMessage={errors[language?.languageCode]?.newsTitle}
                    mode={mode}
                    minLength={2}
                    maxLength={99}
                    {...commonInputProps}
                  />
                  <CustomInput
                    name={`newsDescription-${language?.languageCode}`}
                    placeholder={`News Description (${language?.name})`}
                    label={`News Description (${language?.name})`}
                    value={
                      values?.[language?.languageCode]?.newsDescription?.value
                    }
                    hasError={
                      errors[language?.languageCode]?.newsDescription &&
                      dirty[language?.languageCode]?.newsDescription
                    }
                    errorMessage={
                      errors[language?.languageCode]?.newsDescription
                    }
                    maxLength={500}
                    {...commonInputProps}
                  />
                </StyledFlex>
                <GridContainer marginTop={"10"}>
                  <CustomFileInput
                    fieldName={`thumbNailImage-${language?.languageCode}`}
                    title={`ThumbNail Image (${language?.name})`}
                    description={`Upload An Valid Image (JPEG, max size 5 MB) (${language?.name})`}
                    hasPreview={
                      values?.[language?.languageCode]?.thumbNailImage?.value
                    }
                    preview={
                      values?.[language?.languageCode]?.thumbNailImage?.value
                    }
                    className="news-thumbail-image"
                    accepts={"image/jpeg"}
                    isDragAndDropRequired={true}
                    disableAutoFocus={true}
                    required={true}
                    onChangeHandler={callUploadApi}
                  />
                </GridContainer>
              </div>
            ))
          : null}
        <StyledFlex>
          <CustomDropdown
            fieldName={"newsStatus"}
            label={"Select News Status"}
            onChangeHandler={handleOnSelection}
            value={values?.newsStatus}
            placeholder="Select News Status"
            options={options}
            allowClear={true}
            mode={mode}
            required={true}
            classes={"news-dropdown-width"}
          />
          <CustomInput
            name="redirectionLink"
            placeholder="Redirection Url"
            label="Redirection Url"
            value={values?.redirectionLink}
            hasError={errors.redirectionLink && dirty.redirectionLink}
            errorMessage={errors?.redirectionLink}
            {...commonInputProps}
          />
        </StyledFlex>
      </FormLayout>
    </MainLayout>
  );
};

export default AddEditNewsManagement;
