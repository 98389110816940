import { configureStore } from "@reduxjs/toolkit";
import { articleManagementReducer } from "./article-management/article-management-slice";
import { schoolTypeMasterReducer } from "./school-type-master/school-type-master-slice";
import { programMasterReducer } from "./program-master/program-master-slice";
import { subjectMasterReducer } from "./subject-master/subject-master-slice";
import { dashBoardReducer } from "./dashboard/dashboard-slice";
import { schoolFacilitiesMasterReducer } from "./school-facilities-master/school-facilities-master";
import { gradeMasterReducer } from "./grade-master/grade-master-slice";
import { bannerManagementReducer } from "./banner-management/banner-management-slice";
import { newsManagementReducer } from "./news-management/news-management-slice";
import { ministerUserManagementReducer } from "./minister-user-management/minister-user-management-slice";
import { masterManagementReducer } from "./master-management/master-management-slice";
import { platformUserTypeReducer } from "./platform-user-type-master/platform-user-type-master-slice";
import { schoolManagementReducer } from "./school-management/school-management-slice";
import { REDUX_KEYS_ACTIONS } from "constants/common";

export const store = configureStore({
  reducer: {
    [REDUX_KEYS_ACTIONS.ARTICLE_MANAGEMENT.FEATURE_KEY]:
      articleManagementReducer,
    [REDUX_KEYS_ACTIONS.SCHOOL_TYPE_MASTER.FEATURE_KEY]:
      schoolTypeMasterReducer,
    [REDUX_KEYS_ACTIONS.PROGRAM_MASTER.FEATURE_KEY]: programMasterReducer,
    [REDUX_KEYS_ACTIONS.SUBJECT_MASTER.FEATURE_KEY]: subjectMasterReducer,
    [REDUX_KEYS_ACTIONS.DASHBOARD.FEATURE_KEY]: dashBoardReducer,
    [REDUX_KEYS_ACTIONS.SCHOOL_FACILITIES_MASTER.FEATURE_KEY]:
      schoolFacilitiesMasterReducer,
    [REDUX_KEYS_ACTIONS.GRADE_MASTER.FEATURE_KEY]: gradeMasterReducer,
    [REDUX_KEYS_ACTIONS.BANNER_MANAGEMENT.FEATURE_KEY]: bannerManagementReducer,
    [REDUX_KEYS_ACTIONS.NEWS_MANAGEMENT.FEATURE_KEY]: newsManagementReducer,
    [REDUX_KEYS_ACTIONS.MINISTER_USER_MANAGEMENT.FEATURE_KEY]:
      ministerUserManagementReducer,
    [REDUX_KEYS_ACTIONS.MASTER_MANAGEMENT.FEATURE_KEY]: masterManagementReducer,
    [REDUX_KEYS_ACTIONS.PLAFORM_USER_TYPE_MASTER.FEATURE_KEY]:
      platformUserTypeReducer,
    [REDUX_KEYS_ACTIONS.SCHOOL_MANAGEMENT.FEATURE_KEY]: schoolManagementReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: true,
});
