import React, { useState, Suspense, lazy } from "react";
import RenderTableActionsButton from "components/RenderTableActionsButton";
import { CustomTable } from "components/CustomTable";
import CustomModal from "components/CustomModal";
import { CustomSwapButton } from "components/CustomSwapButton";
import { STATIC_VALUES, STRING_CONSTANTS } from "constants/common";
import "./index.css";

const MainLayout = lazy(() => import("layouts/MainLayout"));
const RenderRequestManagementFilters = lazy(() =>
  import("./RenderRequestManagementFilters")
);

const RequestManagement = () => {
  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const defaultModalProps = { isOpen: false, data: null };
  const [modal, setModal] = useState(defaultModalProps);

  const handleAccept = () => {};

  const handleReject = () => {};

  const columns = [
    {
      field: "ministerID",
      header: STATIC_VALUES.COLUMNS.COMMON.MINISTER_ID,
      customHeaderWidth: "10",
    },
    {
      field: "ministryType",
      header: STATIC_VALUES.COLUMNS.REQUEST_MANAGEMENT.MINISTRY_TYPE,
      enableSort: true,
      customHeaderWidth: "15",
    },
    {
      field: "ministerName",
      header: STATIC_VALUES.COLUMNS.COMMON.SCHOOl_CATEGORY,
      enableSort: true,
      customHeaderWidth: "20",
    },
    {
      field: "email",
      header: STATIC_VALUES.COLUMNS.COMMON.EMAIL_ID,
      enableSort: true,
      customHeaderWidth: "12",
    },
    {
      field: "phoneNumber",
      header: STATIC_VALUES.COLUMNS.REQUEST_MANAGEMENT.PHONE_NUMBER,
      enableSort: true,
      customHeaderWidth: "15",
    },

    {
      field: "IdProofImage",
      header: STATIC_VALUES.COLUMNS.REQUEST_MANAGEMENT.ID_PROOF_IMAGE,
      enableSort: true,
      customHeaderWidth: "10",
    },
    {
      field: "passportImage",
      header: STATIC_VALUES.COLUMNS.REQUEST_MANAGEMENT.PASSPORT_IMAGE,
      enableSort: true,
      customHeaderWidth: "10",
    },
    {
      field: "dlImage",
      header: STATIC_VALUES.COLUMNS.REQUEST_MANAGEMENT.DOCUMENT_IMAGE,
      enableSort: true,
      customHeaderWidth: "10",
    },
    {
      field: "createdDate",
      header: STATIC_VALUES.COLUMNS.COMMON.CREATED_DATE,
      enableSort: true,
    },
    {
      field: "updatedDate",
      header: STATIC_VALUES.COLUMNS.COMMON.UPDATED_DATE,
      enableSort: true,
    },
    {
      field: "acceptReject",
      header: STATIC_VALUES.COLUMNS.REQUEST_MANAGEMENT.ACCEPT_REJECT,
      shouldRenderElement: true,
      renderElement: () => {
        return (
          <CustomSwapButton
            acceptLabel="Accept"
            rejectLabel="Reject"
            buttonHeight="20"
            buttonWidth="45"
            onAccept={handleAccept}
            onReject={handleReject}
            isDisabled={false}
          />
        );
      },
    },
    {
      field: "action",
      header: STATIC_VALUES.COLUMNS.COMMON.ACTIONS,
      shouldRenderElement: true,
      renderElement: () => {
        return (
          <RenderTableActionsButton
            setModal={setModal}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleDeleteClick={() => setModal({ data, isOpen: true })}
            showDeleteIcon
          />
        );
      },
    },
  ];

  const data = [
    {
      ministerID: "1",
      ministryType: "John Brown",
      ministerName: "Name",
      phoneNumber: "9879098789",
      email: "Demo1@gmail.com",
      IdProofImage: "-",
      passportImage: "-",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      ministerID: "2",
      ministryType: "Jim Green",
      ministerName: "Name",
      phoneNumber: "9879098789",
      email: "Demo2@gmail.com",
      IdProofImage: "-",
      passportImage: "-",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      ministerID: "3",
      ministryType: "Joe Black",
      ministerName: "Name",
      phoneNumber: "9879098789",
      email: "Demo3@gmail.com",
      IdProofImage: "-",
      passportImage: "-",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      ministerID: "4",
      ministryType: "Jim Green",
      ministerName: "Name",
      phoneNumber: "9879098789",
      email: "Demo4@gmail.com",
      IdProofImage: "-",
      passportImage: "-",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      ministerID: "5",
      ministryType: "Joe Black",
      ministerName: "Name",
      phoneNumber: "9879098789",
      email: "Demo5@gmail.com",
      IdProofImage: "-",
      passportImage: "-",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      ministerID: "6",
      ministryType: "Jim Green",
      ministerName: "Name",
      phoneNumber: "9879098789",
      email: "Demo6@gmail.com",
      IdProofImage: "-",
      passportImage: "-",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      ministerID: "7",
      ministryType: "Joe Black",
      ministerName: "Name",
      phoneNumber: "9879098789",
      email: "Demo7@gmail.com",
      IdProofImage: "-",
      passportImage: "-",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      ministerID: "8",
      ministryType: "Jim Green",
      ministerName: "Name",
      phoneNumber: "9879098789",
      email: "Demo8@gmail.com",
      IdProofImage: "-",
      passportImage: "-",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
  ];

  const onChangeData = () => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleAddRoute = (params, error) => {};

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  return (
    <Suspense>
      <MainLayout>
        {modal?.isOpen ? (
          <CustomModal
            modal={modal?.isOpen}
            setModal={() => setModal(defaultModalProps)}
            modalTitle={"Delete Modal"}
            modalInnerContent={STRING_CONSTANTS.COMMON.RECORD_DELETE_STRING}
            footerBtnText={"Yes"}
          />
        ) : null}
        <CustomTable
          showAddButton={true}
          handleAddRoute={handleAddRoute}
          isFilter={isFilter}
          tableHeader={"Request Management"}
          handleIsFilter={handleIsFilter}
          filterOptions={
            <Suspense>
              <RenderRequestManagementFilters
                showDateFilter={true}
                showMultiSelectFilter={true}
                multiSelectOptions={multiSelectOptions}
              />
            </Suspense>
          }
          data={data}
          columns={columns}
          count={10}
          wministerIDth={"100"}
          noDataFoundMessage={
            STRING_CONSTANTS.REQUEST_MANAGEMENT_MASTER
              .NO_DATA_FOR_REQUEST_MANAGEMENT_MASTER_FOUND
          }
          class={"table_class"}
          onChangeData={onChangeData}
          btnMarginTop={false}
        />
      </MainLayout>
    </Suspense>
  );
};

export default RequestManagement;
