import React, { useState, Suspense, lazy } from "react";
import { CustomTable } from "components/CustomTable";
import { IconEdit } from "assets/images/SVGs";
import {
  REDUX_KEYS_ACTIONS,
  STATIC_VALUES,
  STRING_CONSTANTS,
} from "constants/common";
import "./dashboard.css";
import { useSelector } from "react-redux";

const MainLayout = lazy(() => import("layouts/MainLayout"));
const DashboardFilters = lazy(() => import("./DashboardFilter"));

const DashboardComponent = () => {
  const { dashBoardReduxData } = useSelector(
    (state) => state[REDUX_KEYS_ACTIONS.DASHBOARD.FEATURE_KEY]
  );
  const [isFilter, setIsFilter] = useState(false);

  const columns = [
    {
      field: "key",
      header: STATIC_VALUES.COLUMNS.DASHBOARD.KEY,
    },
    {
      field: "schoolName",
      header: STATIC_VALUES.COLUMNS.COMMON.SCHOOL_NAME,
      enableSort: true,
    },
    {
      field: "boardName",
      header: STATIC_VALUES.COLUMNS.DASHBOARD.BOARD_NAME,
      enableSort: true,
    },
    {
      field: "studentName",
      header: STATIC_VALUES.COLUMNS.DASHBOARD.STUDENT_NAME,
      enableSort: true,
    },
    {
      field: "status",
      header: STATIC_VALUES.COLUMNS.COMMON.STATUS,
      enableSort: true,
    },
    {
      field: "action",
      header: STATIC_VALUES.COLUMNS.COMMON.ACTIONS,
    },
  ];

  const data = [
    {
      key: "1",
      schoolName: "John Brown",
      boardName: "CBSE",
      studentName: "Riddhi Parmar",
      status: "Active",
      action: (
        <div>
          edit <IconEdit width={20} height={20} />
        </div>
      ),
    },
    {
      key: "2",
      schoolName: "Jim Green",
      boardName: "SCC",
      status: "Active",
      studentName: "Varun Rokade",
    },
    {
      key: "3",
      schoolName: "Joe Black",
      boardName: "CBSE",
      status: "Active",
      studentName: "Akanksha Yadav",
    },
    {
      key: "4",
      schoolName: "Jim Green",
      boardName: "SCC",
      status: "Inactive",
      studentName: "Varun Rokade",
    },
    {
      key: "5",
      schoolName: "Joe Black",
      boardName: "CBSE",
      status: "Inactive",
      studentName: "Akanksha Yadav",
    },
    {
      key: "6",
      schoolName: "Jim Green",
      boardName: "SCC",
      status: "Active",
      studentName: "Varun Rokade",
    },
    {
      key: "7",
      schoolName: "Joe Black",
      boardName: "CBSE",
      status: "Inactive",
      studentName: "Akanksha Yadav",
    },
    {
      key: "8",
      schoolName: "Jim Green",
      boardName: "SCC",
      status: "Active",
      studentName: "Varun Rokade",
    },
  ];

  const onChangeData = () => {};

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <MainLayout>
        <CustomTable
          isFilter={isFilter}
          tableHeader={"Dashboard"}
          handleIsFilter={handleIsFilter}
          filterOptions={
            <Suspense>
              <DashboardFilters
                showDateFilter={true}
                showMultiSelectFilter={true}
                options={multiSelectOptions}
              />
            </Suspense>
          }
          data={data}
          columns={columns}
          count={10}
          width={"100"}
          noDataFoundMessage={STRING_CONSTANTS.COMMON.NO_DATA_FOUND}
          class={"table_class"}
          onChangeData={onChangeData}
        />
      </MainLayout>
    </Suspense>
  );
};

export default DashboardComponent;
