import React, { useState, Suspense, lazy } from "react";
import { ConfigProvider, Tabs } from "antd";
import { useModal } from "hooks/useModal";
import CustomModal from "components/CustomModal";
import Button from "components/CustomButton";
import "./tabStyles.css";
import { REDUX_KEYS_ACTIONS } from "constants/common";
import { useSelector } from "react-redux";

const NationListing = lazy(() => import("./NationListing"));
const RegionListing = lazy(() => import("./RegionListing"));
const ProvinceListing = lazy(() => import("./ProvinceListing"));
const CommuneListing = lazy(() => import("./CommuneListing"));
const VillageListing = lazy(() => import("./VillageListing"));

const AddNation = lazy(() => import("./AddNation"));
const AddRegion = lazy(() => import("./AddRegion"));
const AddProvince = lazy(() => import("./AddProvince"));
const AddCommune = lazy(() => import("./AddCommune"));
const AddVillage = lazy(() => import("./AddVillage"));

const MasterManagementTabs = () => {
  const { masterManagementReduxData } = useSelector(
    (state) => state[REDUX_KEYS_ACTIONS.MASTER_MANAGEMENT.FEATURE_KEY]
  );
  const { isModalOpen, openModal, closeModal, modalType } = useModal();
  const [activeTab, setActiveTab] = useState("1");

  const handleAddButtonClick = () => {
    openModal(activeTab);
  };

  const onChange = (key) => {
    setActiveTab(key);
  };

  const renderModalContent = () => {
    switch (modalType) {
      case "1":
        return <AddNation />;
      case "2":
        return <AddRegion />;
      case "3":
        return <AddProvince />;
      case "4":
        return <AddCommune />;
      case "5":
        return <AddVillage />;
      default:
        return null;
    }
  };

  const items = [
    {
      key: "1",
      label: "Nation",
      children: (
        <>
          <Suspense>
            <div className="tab_children_wrapper">
              <Button
                onClickHandler={handleAddButtonClick}
                btnText={"Add nation"}
                width={100}
                btnMarginTop={"16px"}
              />
            </div>
            <NationListing />
          </Suspense>
        </>
      ),
      itemColor: "red",
    },
    {
      key: "2",
      label: "Region",
      children: (
        <>
          <Suspense>
            <div className="tab_children_wrapper">
              <Button
                onClickHandler={handleAddButtonClick}
                btnText={"Add Region"}
                width={100}
                btnMarginTop={"16px"}
              />
            </div>
            <RegionListing />
          </Suspense>
        </>
      ),
    },
    {
      key: "3",
      label: "Province",
      children: (
        <>
          <Suspense>
            <div className="tab_children_wrapper">
              <Button
                onClickHandler={handleAddButtonClick}
                btnText={"Add Province"}
                width={100}
                btnMarginTop={"16px"}
              />
            </div>
            <ProvinceListing />
          </Suspense>
        </>
      ),
    },
    {
      key: "4",
      label: "Commune",
      children: (
        <>
          <Suspense>
            <div className="tab_children_wrapper">
              <Button
                onClickHandler={handleAddButtonClick}
                btnText={"Add Commune"}
                width={100}
                btnMarginTop={"16px"}
              />
            </div>
            <CommuneListing />
          </Suspense>
        </>
      ),
    },
    {
      key: "5",
      label: "Village/Sector",
      children: (
        <>
          <Suspense>
            <div className="tab_children_wrapper">
              <Button
                onClickHandler={handleAddButtonClick}
                btnText={"Add Village/Sector"}
                width={100}
                btnMarginTop={"16px"}
              />
            </div>
            <VillageListing />
          </Suspense>
        </>
      ),
    },
  ];

  const getModalTitle = (activeTab) => {
    switch (activeTab) {
      case "1":
        return "Nation";
      case "2":
        return "Region";
      case "3":
        return "Province";
      case "4":
        return "Commune";
      case "5":
        return "Village/Sector";
      default:
        return "Unknown";
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            itemColor: "red",
            borderRadius: 2,
          },
        }}
      >
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          inkBar={true}
        />
      </ConfigProvider>

      {isModalOpen(activeTab) ? (
        <Suspense>
          <CustomModal
            modal={isModalOpen(activeTab)}
            setModal={() => closeModal(activeTab)}
            modalTitle={`Add ${getModalTitle(activeTab)}`}
            modalInnerContent={renderModalContent()}
            footerBtnText={"Save"}
          />
        </Suspense>
      ) : null}
    </>
  );
};

export default MasterManagementTabs;
