import React from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "hooks/useForm";
import Dropdown from "components/CustomDropdown";
import Input from "components/CustomInput";
import Textarea from "components/CustomTextarea";
import { StyledFlex } from "constants/commonStyles";
import FormLayout from "layouts/FormLayout";
import MainLayout from "layouts/MainLayout";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";
import "./index.css";
import { FieldTypes } from "constants/fieldTypes";

const SubjectMasterForm = ({ formHeading }) => {
  const location = useLocation();
  const mode = location?.state?.mode;

  const initialState = {
    subjectNameInEnglish: {
      value: "subject name in english",
      error: "",
    },
    subjectNameInFrench: {
      value: "subject name in english",
      error: "",
    },
    descriptionInEnglish: {
      value:
        "ong established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal",
      error: "",
    },
    descriptionInFrench: {
      value:
        "ong established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal",
      error: "",
    },
    grade: {
      value: "qqqqqq",
      error: "",
    },
    titleInEnglish: {
      value: "title in english",
      error: "",
    },
    titleInFrench: {
      value: "title in french",
      error: "",
    },
    schoolCategory: {
      value: "Cycle 1",
      error: "",
    },
    schoolStatus: {
      value: "Active",
      error: "",
    },
    type: {
      value: "Formal Education",
      error: "",
    },
  };

  const handleGradeMasterSubmission = (params, error) => {
    if (mode) {
    } else {
    }
  };

  const { errors, handleOnSubmit, handleOnChange, values, handleOnSelection } =
    useForm(initialState, handleGradeMasterSubmission);

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const commonInputProps = {
    type: FieldTypes.TYPE_TEXT,
    onChangeHandler: handleOnChange,
    mode: mode,
    required: true,
  };

  const commonTextAreaProps = {
    type: FieldTypes.TYPE_TEXT,
    placeholder: "Add Description.",
    onChangeHandler: handleOnChange,
    mode,
    rows: "2",
    cols: "2",
    required: true,
    textareaClass: "grade-master-textarea-content",
  };

  const commonDropdownProps = {
    options: options,
    onChangeHandler: handleOnSelection,
    allowClear: true,
    mode: mode,
    required: true,
  };

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={"Cancel"}
        cancelBtnRoute={ROUTE_CONSTANTS.SUBJECT_MASTER}
        saveBtnText={"Save"}
      >
        <StyledFlex>
          <Input
            name="subjectNameInEnglish"
            placeholder="Subject Name"
            label={"Subject Name" + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values.subjectNameInEnglish}
            {...commonInputProps}
          />
          <Input
            name="subjectNameInFrench"
            placeholder="Subject Name"
            label={"Subject Name" + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values.subjectNameInFrench}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Textarea
            name="descriptionInEnglish"
            label={"Add Description." + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values.descriptionInEnglish}
            {...commonTextAreaProps}
          />
          <Textarea
            name="descriptionInFrench"
            label={"Add Description." + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values.descriptionInFrench}
            {...commonTextAreaProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Input
            name="titleInEnglish"
            placeholder="Title"
            label={"School Title" + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values.titleInEnglish}
            {...commonInputProps}
          />
          <Input
            name="titleInFrench"
            placeholder="Title"
            label={"School Title" + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values.titleInFrench}
            {...commonInputProps}
          />
        </StyledFlex>

        <StyledFlex>
          <Dropdown
            placeholder="Select School Category (Cycle)"
            fieldName={"grade"}
            label={"Select School Category (Cycle)"}
            value={values.schoolCategory}
            {...commonDropdownProps}
          />
          <Dropdown
            fieldName={"grade"}
            label={"Select Grade"}
            placeholder="Select Grade"
            value={values.grade}
            {...commonDropdownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            fieldName={"schoolStatus"}
            label={"Select School Status"}
            placeholder="Select School Status"
            value={values.schoolStatus}
            {...commonDropdownProps}
          />
          <Dropdown
            fieldName={"type"}
            label={"Select School Education Type"}
            placeholder="Select School Education Type"
            value={values.type}
            {...commonDropdownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <Dropdown
            fieldName={"program"}
            label={"Select Program"}
            placeholder="Select Program"
            value={values.program}
            {...commonDropdownProps}
          />
        </StyledFlex>
      </FormLayout>
    </MainLayout>
  );
};

export default SubjectMasterForm;
